import React, {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useState,
} from 'react'
import style from './style.less'
import { useSearchParams } from 'react-router-dom'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import { Popper } from '@mui/material'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuMultiTag from './FilterMenuMultiTag'
import FilterMenuInputTag from './FilterMenuInputTag'
import {
  FILTER_VALUES_MATCHING_INPUT,
  FILTER_VALUES_MATCHING_INPUT_KEYS,
  FILTER_VALUES_MATCHING_MULTI,
  FILTER_VALUES_MATCHING_MULTI_KEYS,
} from './consts'
import { findMatchingValues } from './utils'
import { MergedListKey } from '../SearchQueryBar/SearchQueryBar'
import OperatorSelect from './OperatorSelect'
import FilterMenuDateTag from './FilterMenuDateTag'
import { formatDateRange } from '@yaak/admin/src/helpers/time'

export interface FilterMenuTagProps {
  tag: Record<MergedListKey, string | boolean>
  open: boolean
  setOperatorsRef: any
}

const FilterMenuTag = forwardRef(function FilterMenuMulti(
  { tag, open, setOperatorsRef }: FilterMenuTagProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [range, setRange] = useState<[Date, Date] | null>()
  const hasInput =
    FILTER_VALUES_MATCHING_INPUT[
      findMatchingValues(
        FILTER_VALUES_MATCHING_INPUT,
        tag.value as string
      ) as FILTER_VALUES_MATCHING_INPUT_KEYS
    ]
  const hasMulti =
    FILTER_VALUES_MATCHING_MULTI[
      findMatchingValues(
        FILTER_VALUES_MATCHING_MULTI,
        tag.value as string
      ) as FILTER_VALUES_MATCHING_MULTI_KEYS
    ]
  const hasDate = tag.value.toString().includes('date =')

  const [start, , value] = (tag.value as string).split(' ')

  const deleteTag = useCallback(() => {
    const q = searchParams.get('q')
    if (q) {
      const newQuery = decodeURIComponent(q)
        .split('&')
        .filter((value) => !(tag.key as string)?.includes(value))
        .join('&')
      searchParams.set('q', newQuery)
      setSearchParams(searchParams)
    }
  }, [searchParams, tag])

  return (
    <Popper
      className={style.filterMenuMulti}
      anchorEl={(ref as any)?.current}
      disablePortal
      open={open}
      style={{ zIndex: 1 }}
      placement={'bottom-start'}
    >
      <div className={style.filterMenuMultiHeader}>
        <div className={style.filterMenuMultiHeaderValue}>
          <Typography type={TypographyTypes.body} size={TypographySizes.small}>
            {start}
          </Typography>
          <OperatorSelect tag={tag} setOperatorsRef={setOperatorsRef} />
          {hasDate ? (
            <Typography
              type={TypographyTypes.body}
              size={TypographySizes.small}
            >
              {range && formatDateRange(range[0], range[1])}
            </Typography>
          ) : (
            value && (
              <Typography
                type={TypographyTypes.body}
                size={TypographySizes.small}
              >{`${value} ${tag.unit}`}</Typography>
            )
          )}
        </div>
        <Icon
          name={'Delete'}
          version={Version.v2}
          color={'new-color-red-050'}
          className={style.deleteIcon}
          onClick={deleteTag}
        />
      </div>
      <div className={style.filterMenuMultiTags}>
        {hasMulti && <FilterMenuMultiTag tag={tag} multi={hasMulti} />}
        {hasInput && <FilterMenuInputTag tag={tag} input={hasInput} />}
        {hasDate && (
          <FilterMenuDateTag tag={tag} range={range} setRange={setRange} />
        )}
      </div>
    </Popper>
  )
})

export default memo(FilterMenuTag)
