// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__form--pwqA3 > label {\n  margin-top: -0.5rem;\n}\n.style__select--qAmdy fieldset {\n  border: none;\n}\n.style__select--qAmdy > div {\n  padding: 7px;\n  padding-right: 5px !important;\n}\n.style__iconSelect--cHIFc {\n  padding-bottom: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/AddPanelSelect/style.less"],"names":[],"mappings":"AAAA;EAGM,mBAAA;AADN;AAFA;EAQM,YAAA;AAHN;AALA;EAWM,YAAA;EACA,6BAAA;AAHN;AATA;EAgBI,mBAAA;AAJJ","sourcesContent":[":local {\n  .form {\n    > label {\n      margin-top: -0.5rem;\n    }\n  }\n  .select {\n    fieldset {\n      border: none;\n    }\n    > div {\n      padding: 7px;\n      padding-right: 5px !important;\n    }\n  }\n  .iconSelect {\n    padding-bottom: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "style__form--pwqA3",
	"select": "style__select--qAmdy",
	"iconSelect": "style__iconSelect--cHIFc"
};
export default ___CSS_LOADER_EXPORT___;
