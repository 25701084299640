import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ChartKeys, useMetadataStore } from '../../stores/MetadataStore'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { subtractTime, toSec } from '../../utils/time'
import VerticalBar from './VerticalBar'
import { calculateAcceleration, Timestamp } from '../../utils/protobufParse'
import style from './style.less'
import { useVideosStore } from '../../stores/VideosStore'
import { useSearchParams } from 'react-router-dom'
import {
  AxisTickStrategies,
  ColorHEX,
  ColorRGBA,
  disableThemeEffects,
  transparentFill,
  transparentLine,
  lightningChart,
  SolidFill,
  SolidLine,
  Themes,
  ChartXY,
  UIBackground,
  PointLineAreaSeries,
} from '@lightningchart/lcjs'
import {
  getChartAxisXMax,
  getChartAxisXMin,
  getChartLeftPosition,
  getChartRightPosition,
  getXValueAtPixel,
} from './utils'
import config from '@yaak/components/services/api/config'
import { renderExternalTooltip } from './tooltip'

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

interface IDataset {
  label: string
  data: any[]
  backgroundColor: string
}

const addLineSeries = (chart: ChartXY<UIBackground>, dataset: IDataset) => {
  const lineSeries = chart
    .addPointLineAreaSeries({ dataPattern: 'ProgressiveX' })
    .setStrokeStyle(transparentLine)
    .setAreaFillStyle(transparentFill)

  lineSeries.setName(dataset.label)
  lineSeries.appendJSON(dataset.data)
  lineSeries.setStrokeStyle(
    new SolidLine({
      thickness: 2,
      fillStyle: new SolidFill({
        color: ColorHEX(dataset.backgroundColor),
      }),
    })
  )
  return lineSeries
}

const DEFAULT_INTERVAL = { start: 0, end: 10 }

interface Data {
  x: number
  y: number
  time_stamp: Timestamp
}

const createData = (data: any, startTime: Timestamp, value: number): Data => {
  return {
    x: toSec(subtractTime(data.time_stamp, startTime)),
    y: value || 0,
    time_stamp: data.time_stamp,
  }
}

interface VehicleMotionChartProps {}

const VehicleMotionChart: React.FunctionComponent<
  VehicleMotionChartProps
> = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { metadata, updateChart, settings, chart } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
      settings: state.chartSettings,
      updateChart: state.updateChart,
      chart: state.chart,
    }))
  )

  const { update, begin, end, offset } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      end: state.end,
      offset: state.offset,
      update: state.update,
    }))
  )

  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )

  const [data, setData] = useState<Data[][]>([])
  const [length, setLength] = useState<number>()
  const prev = usePrevious(length)
  const [lineSeries, setLineSeries] = useState<PointLineAreaSeries[]>([])

  const datasets: IDataset[] = useMemo(
    () => [
      {
        label: 'brake_pedal_normalized',
        data: [],
        backgroundColor: LESS_COLORS['color-lime-036'],
      },
      {
        label: 'gas_pedal_normalized',
        data: [],
        backgroundColor: LESS_COLORS['color-lime-062'],
      },
      {
        label: 'steering_angle_normalized',
        data: [],
        backgroundColor: LESS_COLORS['new-color-purple-039'],
      },
      {
        label: 'speed',
        data: [],
        backgroundColor: LESS_COLORS['new-color-yellow-059'],
      },
      {
        label: 'steering_angle',
        data: [],
        backgroundColor: LESS_COLORS['new-color-purple-069'],
      },
      {
        label: 'acceleration',
        data: [],
        backgroundColor: LESS_COLORS['color-pink-074'],
      },
    ],
    []
  )

  useEffect(() => {
    const slicedData = prev
      ? metadata.vehicleMotion?.slice(prev)
      : metadata.vehicleMotion
    setLength(metadata.vehicleMotion?.length)

    const start = begin
      ? {
          seconds: new Date(session?.startTimestamp).getTime() / 1000,
          nanos: 0,
        }
      : metadata.vehicleMotion?.[0]?.time_stamp
    const breakPedalNormalized: Data[] = slicedData?.map((vM) => {
      return createData(vM, start, vM.brake_pedal_normalized)
    })
    const gasPedalNormalized = slicedData?.map((vM) =>
      createData(vM, start, vM.gas_pedal_normalized)
    )
    const steeringAngleNormalized = slicedData?.map((vM) =>
      createData(vM, start, vM.steering_angle_normalized)
    )
    const speed = slicedData?.map((vM) => createData(vM, start, vM.speed))
    const steeringAngle = slicedData?.map((vM) =>
      createData(vM, start, vM.steering_angle)
    )
    const acceleration = slicedData?.map((vM) =>
      createData(vM, start, calculateAcceleration(vM))
    )

    setData([
      breakPedalNormalized,
      gasPedalNormalized,
      steeringAngleNormalized,
      speed,
      steeringAngle,
      acceleration,
    ])
  }, [metadata, prev, begin])

  useEffect(() => {
    if (data.length > 0) {
      datasets.forEach((dataset: IDataset, i: number) => {
        if (data[i]) {
          for (let k = 0; k < data[i].length; k++) {
            // Manually push items one by one to avoid maximum call stack
            dataset.data.push(data[i][k])
          }
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (chart) {
      const min: number[] = []
      const max: number[] = []
      Object.keys(settings).forEach((s) => {
        const lS = lineSeries.filter((lS: any) => lS.getName() === s)[0]
        if (lS) {
          if (settings[s as ChartKeys].display) {
            lS.setVisible(true)

            const minY = lS.getYMin()
            const maxY = lS.getYMax()
            if (minY !== undefined) min.push(minY)
            if (maxY !== undefined) max.push(maxY)
          } else {
            lS.setVisible(false)
          }
        }
      })
      if (
        min.length > 0 &&
        max.length > 0 &&
        (Math.min(...min) !== 0 || Math.max(...max) !== 0)
      ) {
        chart.getDefaultAxisY().setIntervalRestrictions(() => ({
          startMin: Math.min(...min),
          endMax: Math.max(...max),
        }))
      }
    }
  }, [settings, lineSeries, datasets])

  useEffect(() => {
    return () => {
      chart?.dispose()
    }
  }, [chart])

  useEffect(() => {
    if (chart) {
      const { start, end } = chart.getDefaultAxisX().getInterval()
      const chartSize = chart.getDefaultAxisX().getIntervalRestrictions()
      // Checking if chart is zoomed
      if (
        !(start === DEFAULT_INTERVAL.start && end === DEFAULT_INTERVAL.end) &&
        (start !== chartSize?.startMin || end !== chartSize?.endMax)
      ) {
        const interval = (end - start) / 2
        chart.getDefaultAxisX().setInterval({
          start: offset - interval,
          end: offset + interval,
        })
      }
    }
  }, [offset, chart])

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      if (chart) {
        const rect = event.currentTarget.getBoundingClientRect()
        const mouseX = event.clientX - rect.left
        const seconds = getXValueAtPixel({
          pixelX: mouseX,
          min: getChartAxisXMin(chart),
          max: getChartAxisXMax(chart),
          left: getChartLeftPosition(chart),
          right: getChartRightPosition(chart),
        })
        update({
          hoverSync: seconds,
        })
      }
    },
    [chart]
  )

  const onClick = useCallback(
    (event: any) => {
      if (chart) {
        const rect = event.currentTarget.getBoundingClientRect()
        const mouseX = event.clientX - rect.left
        const seconds = getXValueAtPixel({
          pixelX: mouseX,
          min: getChartAxisXMin(chart),
          max: getChartAxisXMax(chart),
          left: getChartLeftPosition(chart),
          right: getChartRightPosition(chart),
        })
        update({
          offset: seconds,
          jump: seconds,
        })
        searchParams.set('offset', seconds.toString())
        setSearchParams(searchParams, { replace: true })
      }
    },
    [chart, searchParams]
  )

  useEffect(() => {
    if (datasets[0]?.data?.length > 0) {
      const lc = lightningChart({
        license: config.lc.license,
        licenseInformation: {
          appTitle: 'LightningChart JS Trial',
          company: 'LightningChart Ltd.',
        },
        overrideInteractionMouseButtons: {
          chartXYPanMouseButton: 0,
          chartXYRectangleZoomFitMouseButton: 2,
        },
      })
      const chart = lc
        .ChartXY({
          container: document.getElementById(
            'chart-container'
          ) as HTMLDivElement,
          animationsEnabled: false,
          theme: disableThemeEffects(Themes.darkGold),
        })
        .setTitle('')
      updateChart(chart)
      chart.setBackgroundFillStyle(
        new SolidFill({ color: ColorRGBA(255, 255, 255) })
      )
      chart
        .setSeriesBackgroundFillStyle(transparentFill)
        .setSeriesBackgroundStrokeStyle(transparentLine)
      chart.setPadding({ left: 0, right: 5, top: 5, bottom: 8 })
      chart
        .getDefaultAxisX()
        .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
          ticks
            .setMajorTickStyle((major) =>
              major
                .setLabelFont((font) => font.setSize(10))
                .setLabelFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0) }))
            )
            .setMinorTickStyle((minor) =>
              minor
                .setLabelFont((font) => font.setSize(10))
                .setLabelFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0) }))
            )
        )
      chart
        .getDefaultAxisY()
        .setTickStrategy(AxisTickStrategies.Numeric, (ticks) =>
          ticks
            .setMajorTickStyle((major) =>
              major
                .setLabelFont((font) => font.setSize(10))
                .setLabelFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0) }))
            )
            .setMinorTickStyle((minor) =>
              minor
                .setLabelFont((font) => font.setSize(10))
                .setLabelFillStyle(new SolidFill({ color: ColorRGBA(0, 0, 0) }))
            )
        )
      chart.getDefaultAxisX().setIntervalRestrictions((state) => ({
        startMin: state.dataMin,
        endMax: state.dataMax,
      }))
      chart.getDefaultAxisY().setChartInteractions(false)

      chart.setCursor((cursor) =>
        cursor.setTickMarkerXVisible(false).setTickMarkerYVisible(false)
      )
      const band = chart
        .getDefaultAxisX()
        .addBand(false)
        .setValueStart(begin)
        .setValueEnd(end)

      band
        .setFillStyle(new SolidFill({ color: ColorRGBA(89, 96, 222, 0.12) }))
        .setStrokeStyle(new SolidLine({ thickness: 0 }))
        .setMouseInteractions(false)
        .setHighlightOnHover(false)
        .setHighlight(true)

      chart.getDefaultAxisX().setStrokeStyle(
        new SolidLine({
          thickness: 1,
          fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0) }),
        })
      )
      chart.getDefaultAxisY().setStrokeStyle(
        new SolidLine({
          thickness: 1,
          fillStyle: new SolidFill({ color: ColorRGBA(0, 0, 0) }),
        })
      )
      chart.setSeriesHighlightOnHover(false)

      const html = document.createElement('div')
      document.body.append(html)
      html.className = style.tooltip
      chart.setCustomCursor((_, hit, hits, mouseLocation) => {
        if (hit) {
          html.style.opacity = '1.0'
          const locClient = chart.translateCoordinate(
            hit,
            chart.coordsAxis,
            chart.coordsClient
          )

          const closestY = hits
            ?.map(
              (hitVal) =>
                chart.translateCoordinate(
                  hitVal,
                  chart.coordsAxis,
                  chart.coordsClient
                ).clientY
            )
            .reduce(function (prev, curr) {
              return Math.abs(curr - mouseLocation.clientY) <
                Math.abs(prev - mouseLocation.clientY)
                ? curr
                : prev
            })
          html.innerHTML = hits ? renderExternalTooltip(hits) : ''
          html.style.left = `${locClient.clientX - html.offsetWidth + 10}px`
          html.style.top = `${closestY ? closestY - 6 : locClient}px`
        } else {
          html.style.opacity = '0.0'
        }
      })

      const lineSeries: PointLineAreaSeries[] = []
      datasets.forEach((dataset) => {
        lineSeries.push(addLineSeries(chart, dataset))
      })
      setLineSeries(lineSeries)
    }
  }, [datasets, data])

  return (
    <div className={style.chartContainer}>
      <div
        className={style.chart}
        id={'chart-container'}
        onMouseMove={onMouseMove}
        onClick={onClick}
      />
      <VerticalBar color={'#000'} />
      <VerticalBar hover={true} color={LESS_COLORS['new-color-yellow-059']} />
    </div>
  )
}

export default memo(VehicleMotionChart)
