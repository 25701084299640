import React, { memo, useCallback, useEffect, useMemo } from 'react'
import NestedSelect, { ISelectMenuItem } from '../NestedSelect'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'

interface GridItemSelectStoreState {
  windowType: string
  setWindowType: (val: string) => void
}

export const useGridItemSelectStore = create<GridItemSelectStoreState>(
  (set) => ({
    windowType: '',
    setWindowType: (val) => set(() => ({ windowType: val })),
  })
)

interface GridItemSelectProps {
  sensorTopics: string[]
  mapData?: boolean
}

const availableCameras = [
  'CAM_FRONT_CENTER',
  'CAM_LEFT_BACKWARD',
  'CAM_REAR',
  'CAM_RIGHT_BACKWARD',
]

const GridItemSelect = ({
  mapData,
  sensorTopics = availableCameras,
}: GridItemSelectProps) => {
  const { windowType, setWindowType } = useGridItemSelectStore(
    useShallow((state) => ({
      windowType: state.windowType,
      setWindowType: state.setWindowType,
    }))
  )

  useEffect(() => setWindowType(sensorTopics[0]), [])

  const handleChange = useCallback((val?: string) => {
    if (val) {
      setWindowType(val)
    }
  }, [])

  const options: ISelectMenuItem[] = useMemo(
    () => [
      {
        label: 'Map data',
        value: 'MAP DATA',
        icon: 'Location',
        disabled: !mapData,
      },
      {
        label: 'Sensor topic',
        value: 'topic',
        icon: 'Sensors',
        isSearchable: true,
        subMenuItems: sensorTopics.map((v) => ({ label: v, value: v })),
      },
    ],
    [mapData, sensorTopics]
  )

  const transformValue = useCallback(
    (val: string) => (val === 'MAP DATA' ? 'MAP DATA' : `/topic/${val}`),
    []
  )

  return (
    <NestedSelect
      value={windowType}
      onChange={handleChange}
      options={options}
      transformValue={transformValue}
    />
  )
}

export default memo(GridItemSelect)
