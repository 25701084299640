import { create } from 'zustand'

export interface CameraState {
  buffering: boolean
}

export interface PlayerStoreProps {
  context: number
  begin: number
  end: number
  jump: number
  offset: number
  buffering: boolean
  playing: boolean
  cameraStates: {
    [id: string]: CameraState
  }
  playbackRate: number
  hoverSync: number
}

interface PlayerStoreActions {
  update: (store: any) => void
  setCameraState: (id: string, state: CameraState) => void
  setOffset: (offset: number) => void
  setBegin: (begin: number) => void
  setEnd: (end: number) => void
  setContext: (context: number) => void
  removeCameraState: (id: string) => void
  reset: () => void
}

const initialState: PlayerStoreProps = {
  context: 0,
  begin: 0,
  end: 0,
  buffering: true,
  jump: 0,
  offset: 0,
  playing: false,
  cameraStates: {},
  playbackRate: 1,
  hoverSync: 0,
}

export const usePlayerStore = create<PlayerStoreProps & PlayerStoreActions>(
  (set) => ({
    ...initialState,
    update: (store) => set(() => ({ ...store })),
    setOffset: (offset) => set(() => ({ offset })),
    setBegin: (begin) => set(() => ({ begin })),
    setEnd: (end) => set(() => ({ end })),
    setContext: (context) => set(() => ({ context })),
    setCameraState: (id, state) =>
      set((store) => {
        store.cameraStates[id] = state
        store.buffering =
          Object.keys(store.cameraStates).find(
            (key) => store.cameraStates[key].buffering
          ) !== undefined
        return { buffering: store.buffering, cameraStates: store.cameraStates }
      }),
    removeCameraState: (id) =>
      set((store) => ({
        cameraStates: Object.keys(store.cameraStates).reduce(
          (pre, curr) =>
            curr === id
              ? pre
              : { ...{ [curr]: store.cameraStates[curr] }, ...pre },
          {}
        ),
      })),
    reset: () => {
      set(initialState)
    },
  })
)
