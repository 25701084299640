interface tooltipCallbacksProps {
  unit?: string
  formatValue?: (value: number | undefined) => string | undefined
}
export const tooltipCallbacks = ({
  unit = '',
  formatValue,
}: tooltipCallbacksProps) => {
  return {
    label: (context: any) => {
      const label = context.label || context.dataset.label
      const currentValue = context.raw
      let total = 0
      const datasets = context.chart.data.datasets

      for (let i = 0; i < datasets.length; i++) {
        total += datasets[i].data.reduce((a: number, b: number) => a + b, 0)
      }

      const percentage = parseFloat(((currentValue / total) * 100).toFixed(1))

      return `${label}: ${
        formatValue ? formatValue(currentValue) : currentValue
      }${unit} (${percentage}%)`
    },
  }
}

export const HORIZONTAL_BAR_CHART_COLORS: Record<string, string> = {
  // Lighting
  Day: LESS_COLORS['color-lime-062'],
  Night: LESS_COLORS['color-lime-084'],
  Transition: LESS_COLORS['color-lime-044'],
  // Weather
  Clear: LESS_COLORS['color-lime-062'],
  Clouds: LESS_COLORS['color-lime-084'],
  Drizzle: LESS_COLORS['color-pink-054'],
  Fog: LESS_COLORS['color-pink-084'],
  Mist: LESS_COLORS['new-color-yellow-059'],
  Rain: LESS_COLORS['new-color-yellow-085'],
  Snow: LESS_COLORS['new-color-blue-070'],
  // Lane count
  0: LESS_COLORS['new-color-blue-070'],
  1: LESS_COLORS['new-color-blue-090'],
  2: LESS_COLORS['color-lime-062'],
  3: LESS_COLORS['color-lime-084'],
  4: LESS_COLORS['color-pink-054'],
  5: LESS_COLORS['color-pink-084'],
  6: LESS_COLORS['new-color-yellow-059'],
  7: LESS_COLORS['new-color-yellow-085'],
  Unknown: LESS_COLORS['color-lime-044'],
  // Average drive duration
  Expert: LESS_COLORS['color-lime-062'],
  Student: LESS_COLORS['color-lime-084'],
}

export const DOUGHNUT_CHART_COLORS: Record<string, string> = {
  // Road type
  Motorway: LESS_COLORS['color-lime-084'],
  'Motorway link': LESS_COLORS['color-lime-044'],
  Primary: LESS_COLORS['color-pink-084'],
  'Primary link': LESS_COLORS['color-pink-054'],
  Residential: LESS_COLORS['new-color-yellow-085'],
  Secondary: LESS_COLORS['new-color-yellow-059'],
  'Secondary link': LESS_COLORS['new-color-blue-090'],
  Service: LESS_COLORS['new-color-blue-070'],
  Tertiary: LESS_COLORS['color-lime-062'],
  Trunk: LESS_COLORS['color-lime-036'],
  'Trunk link': LESS_COLORS['color-pink-074'],
  Unclassified: LESS_COLORS['color-pink-044'],
  Unknown: LESS_COLORS['new-color-yellow-069'],
  // incidents
  Distance: LESS_COLORS['color-pink-074'],
  Handling: LESS_COLORS['new-color-yellow-069'],
  'Instructor Pedal': LESS_COLORS['color-lime-062'],
  Obstruction: LESS_COLORS['color-lime-084'],
  Parking: LESS_COLORS['color-pink-054'],
  Placement: LESS_COLORS['color-lime-084'],
  'Right of Way': LESS_COLORS['color-pink-054'],
  'Signs & Lights': LESS_COLORS['color-pink-084'],
  Speed: LESS_COLORS['new-color-yellow-059'],
  'Too Fast': LESS_COLORS['new-color-yellow-085'],
  'Too Slow': LESS_COLORS['new-color-blue-070'],
  'Traffic light': LESS_COLORS['new-color-yellow-085'],
  'Turn Signals': LESS_COLORS['new-color-blue-090'],
  'Visual Check': LESS_COLORS['color-lime-044'],
  // Driving hours
  Expert: LESS_COLORS['color-lime-062'],
  Student: LESS_COLORS['color-lime-084'],
  // Map Features
  BUS_STOP: LESS_COLORS['color-lime-062'],
  ENTERING_MOVING_TRAFFIC: LESS_COLORS['color-lime-036'],
  GIVE_WAY: LESS_COLORS['new-color-blue-090'],
  HILL_DRIVE: LESS_COLORS['new-color-blue-059'],
  LIMITED_ACCESS_WAY: LESS_COLORS['new-color-yellow-085'],
  LIVING_STREET: LESS_COLORS['new-color-yellow-059'],
  LOWERED_KERB: LESS_COLORS['new-color-red-066'],
  LOW_SPEED_REGION: LESS_COLORS['new-color-red-050'],
  MERGE_IN_OUT_ON_HIGHWAY: LESS_COLORS['new-color-purple-084'],
  MULTILANE_LEFT: LESS_COLORS['new-color-purple-069'],
  MULTILANE_RIGHT: LESS_COLORS['new-color-orange-063'],
  ONE_WAY: LESS_COLORS['new-color-orange-037'],
  PEDESTRIAN_CROSSING: LESS_COLORS['color-pink-084'],
  PRIORITY_FORWARD_BACKWARD: LESS_COLORS['color-pink-054'],
  PRIORITY_WAY: LESS_COLORS['color-lime-084'],
  PROTECTED_LEFT: LESS_COLORS['color-lime-044'],
  RIGHT_BEFORE_LEFT: LESS_COLORS['new-color-blue-070'],
  RIGHT_OF_WAY: LESS_COLORS['new-color-blue-036'],
  RIGHT_TURN_ON_RED: LESS_COLORS['new-color-yellow-069'],
  ROAD_NARROWS: LESS_COLORS['new-color-yellow-037'],
  ROUNDABOUT: LESS_COLORS['new-color-red-090'],
  TRAFFIC_CALMER: LESS_COLORS['new-color-purple-059'],
  TRAIN_CROSSING: LESS_COLORS['new-color-purple-039'],
  TRAM_TRACKS: LESS_COLORS['new-color-orange-090'],
  TUNNEL: LESS_COLORS['new-color-orange-063'],
  UNPROTECTED_LEFT: LESS_COLORS['color-pink-074'],
  UNPROTECTED_RIGHT_WITH_BIKE: LESS_COLORS['color-pink-044'],
}

export const DEFAULT_COLOR = LESS_COLORS['color-neutral-050']
