import React, { memo, useCallback, useLayoutEffect, useRef } from 'react'
import style from './style.less'
import { useMetadataStore } from '../../stores/MetadataStore'
import { useShallow } from 'zustand/react/shallow'
import { usePlayerStore } from '../../stores/PlayerStore'
import {
  getChartAxisXMax,
  getChartAxisXMin,
  getChartLeftPosition,
  getChartRightPosition,
  getPixelForXValue,
} from './utils'

interface VerticalBarProps {
  hover?: boolean
  color: string
}

const VerticalBar = ({ hover, color }: VerticalBarProps) => {
  const verticalBarRef = useRef<HTMLDivElement>(null)
  const chartCurrent = useMetadataStore(
    useShallow((state) => ({
      chart: state.chart,
    }))
  )

  const { hoverSync, offset } = usePlayerStore(
    useShallow((state) => ({
      hoverSync: state.hoverSync,
      offset: state.offset,
    }))
  )

  const updateBars = useCallback(() => {
    if (!verticalBarRef.current) {
      return
    }

    if (chartCurrent && chartCurrent.chart) {
      if (hover) {
        const hoverValuePixel = getPixelForXValue({
          xValue: hoverSync,
          min: getChartAxisXMin(chartCurrent.chart),
          max: getChartAxisXMax(chartCurrent.chart),
          left: getChartLeftPosition(chartCurrent.chart),
          right: getChartRightPosition(chartCurrent.chart),
        })

        if (hoverValuePixel && hoverSync) {
          verticalBarRef.current.style.display = 'block'
          verticalBarRef.current.style.transform = `translateX(${hoverValuePixel}px)`
        } else {
          verticalBarRef.current.style.display = 'none'
        }
      } else {
        const hoverValuePixel = getPixelForXValue({
          xValue: offset,
          min: getChartAxisXMin(chartCurrent.chart),
          max: getChartAxisXMax(chartCurrent.chart),
          left: getChartLeftPosition(chartCurrent.chart),
          right: getChartRightPosition(chartCurrent.chart),
        })

        if (hoverValuePixel) {
          verticalBarRef.current.style.display = 'block'
          verticalBarRef.current.style.transform = `translateX(${hoverValuePixel}px)`
        } else {
          verticalBarRef.current.style.display = 'none'
        }
      }
    }
  }, [offset, hoverSync, hover, chartCurrent, verticalBarRef])

  useLayoutEffect(() => {
    updateBars()
  }, [updateBars])

  return (
    <div
      ref={verticalBarRef}
      className={style.verticalBar}
      style={{ backgroundColor: color }}
    />
  )
}

export default memo(VerticalBar)
