import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import style from './style.less'
import Icon from '@yaak/components/src/Icon/Icon'
import MenuItem from '@mui/material/MenuItem'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import { useMosaicStore } from '../../stores/MosaicStore'
import { MosaicNode } from 'react-mosaic-component'
import { useVideosStore } from '../../stores/VideosStore'
import { useVideoPlayerStore } from '../../stores/VideoPlayerStore'
import {
  createMosaicStoreTree,
  createVideoConfigTree,
  searchCamerasInLayout,
} from './utils'
import FormControl from '@mui/material/FormControl'
import { useShallow } from 'zustand/react/shallow'

const PANELS = ['panel-videos', 'panel-map', 'panel-metadata', 'panel-plot']
const PANEL_NAMES = {
  'Add panel': 'Add panel',
  'panel-videos': 'Add sensor topic',
  'panel-map': 'Add map',
  'panel-metadata': 'Add metadata',
  'panel-plot': 'Add plot',
}

const updateCurrentPanels = (config: MosaicNode<any>) => {
  const result = []
  let second = config.second
  if (typeof config === 'string') {
    result.push(config)
  } else {
    result.push(config.first)
    while (second) {
      second.first && result.push(second.first)
      if (typeof second === 'string') {
        result.push(second)
      }
      second = second.second
    }
  }
  return result
}

const AddPanelSelect = () => {
  const {
    selectedCameras,
    cameraPositions,
    updateSelectedCameras,
    cameras,
    updateRefresh,
  } = useVideosStore(
    useShallow((state) => ({
      selectedCameras: state.selectedCameras,
      cameraPositions: state.cameraPositions,
      updateSelectedCameras: state.updateSelectedCameras,
      cameras: state.cameras,
      updateRefresh: state.updateRefresh,
    }))
  )

  const { config, update } = useMosaicStore(
    useShallow((state) => ({
      config: state.config,
      update: state.update,
    }))
  )

  const { videoConfig, updateVideoConfig } = useVideoPlayerStore(
    useShallow((state) => ({
      videoConfig: state.config,
      updateVideoConfig: state.update,
    }))
  )
  const [availablePanels, setAvailablePanels] = useState<string[]>([])

  useEffect(() => {
    const currentPanels = updateCurrentPanels(config)
    const panels = PANELS.filter((panel) => currentPanels.indexOf(panel) === -1)
    if (selectedCameras.length < cameraPositions.length) {
      panels.unshift(PANELS[0])
    }
    setAvailablePanels(panels)
  }, [config, selectedCameras.length, cameraPositions, videoConfig])

  return (
    <FormControl className={style.form} disabled={availablePanels.length === 0}>
      <Select
        displayEmpty
        id={'addPanelSelect'}
        value={''}
        className={style.select}
        onChange={(event) => {
          const value = event.target.value

          if (value === 'panel-videos') {
            const existingCamerasPrev = searchCamerasInLayout(videoConfig)
            const updatedCameraPrev = cameras.filter(
              (c) => !existingCamerasPrev.includes(c.name)
            )[0]
            const videoConfigTree = createVideoConfigTree(
              videoConfig,
              updatedCameraPrev.name
            )
            updateVideoConfig(videoConfigTree)
            const existingCameras = searchCamerasInLayout(videoConfigTree)
            const updatedCamera = existingCameras.filter(
              (c) => !selectedCameras.includes(c)
            )[0]
            const updatedCameras = selectedCameras
            updatedCameras.push(updatedCamera)
            updateSelectedCameras(updatedCameras)
            updateRefresh(true)
          } else {
            const mosaicStoreTree = createMosaicStoreTree(config, value)
            mosaicStoreTree && update(mosaicStoreTree)
          }
        }}
        IconComponent={(_props) => {
          const opened = _props.className.toString().includes('iconOpen')
          return (
            <Icon
              className={style.iconSelect}
              version={Version.v2}
              name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
            />
          )
        }}
      >
        <MenuItem disabled value="">
          <Typography
            type={TypographyTypes.body}
            size={TypographySizes.small}
            version={Version.v2}
          >
            Add panel
          </Typography>
        </MenuItem>
        {availablePanels.map((panel, index) => (
          <MenuItem key={index} value={panel}>
            <Typography type={TypographyTypes.body} version={Version.v2}>
              {PANEL_NAMES[panel as keyof typeof PANEL_NAMES]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default AddPanelSelect
