import classNames from 'classnames'
import React, { memo } from 'react'
import styleV1 from './style.less'
import styleV2 from './style-v2.less'
import { Version } from '../types'

export const IconSizes = {
  large: 'large',
  normal: 'normal',
  medium: 'medium',
}

export const IconTypes = {
  regular: 'regular',
  bold: 'bold',
}

export interface IconProps {
  name: string
  className?: string
  styles?: any
  size?: string
  type?: string
  version?: Version
  onClick?: (event?: any) => void
  dataTestId?: string
  color?: string
}

const Icon: React.FunctionComponent<IconProps> = ({
  className,
  name,
  styles,
  size = IconSizes.normal,
  version = Version.v1,
  type,
  color,
  onClick,
  dataTestId,
}) => {
  const style = version === Version.v1 ? styleV1 : styleV2
  return (
    <span
      className={classNames(
        style.icon,
        style[`icon${name}`],
        className,
        style[`icon${size}`],
        style[`icon${type}`]
      )}
      style={{ color: LESS_COLORS[color || ''], ...styles }}
      onClick={onClick}
      data-testid={dataTestId}
    />
  )
}

export default memo(Icon)
