// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__settings--vcArO {\n  margin: 1rem;\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n}\n.style__row--V8cKV {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 1rem;\n}\n.style__inputField--oerWg {\n  width: 100%;\n}\n.style__select--NkbCh fieldset {\n  border: none;\n}\n.style__select--NkbCh div {\n  padding: 0.125rem !important;\n}\n.style__label--A3KSt {\n  min-width: 3rem;\n}\n.style__dot--ltbT1 {\n  height: 0.5rem;\n  width: 0.5rem;\n  background-color: red;\n  border-radius: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Settings/style.less"],"names":[],"mappings":"AAEA;EAEI,YAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAFJ;AAHA;EASI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AAHJ;AATA;EAgBI,WAAA;AAJJ;AAZA;EAqBM,YAAA;AANN;AAfA;EAwBM,4BAAA;AANN;AAlBA;EA6BI,eAAA;AARJ;AArBA;EAiCI,cAAA;EACA,aAAA;EACA,qBAAA;EACA,kBAAA;AATJ","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .settings {\n    margin: @spacing-100;\n    display: flex;\n    flex-direction: column;\n    gap: @spacing-100;\n  }\n\n  .row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: @spacing-100;\n  }\n\n  .inputField {\n    width: 100%;\n  }\n\n  .select {\n    fieldset {\n      border: none;\n    }\n    div > {\n      padding: @spacing-15 !important;\n    }\n  }\n\n  .label {\n    min-width: @spacing-300;\n  }\n\n  .dot {\n    height: 0.5rem;\n    width: 0.5rem;\n    background-color: red;\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": "style__settings--vcArO",
	"row": "style__row--V8cKV",
	"inputField": "style__inputField--oerWg",
	"select": "style__select--NkbCh",
	"label": "style__label--A3KSt",
	"dot": "style__dot--ltbT1"
};
export default ___CSS_LOADER_EXPORT___;
