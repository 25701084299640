import React, { useEffect, useRef, useState } from 'react'
import style from './style.less'
import { useAuth0 } from '@auth0/auth0-react'
import Typography, {
  TypographySizes,
  TypographyStyles,
  TypographyTypes,
} from '../Typography/Typography'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import Tooltip from '../Tooltip'
import Icon from '../Icon'
import { IconSizes } from '../Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { Version } from '../types'

export interface ProfileProps {
  bundledRelease?: {
    commitHash: string
    releasedAt: string
  }
  onViewProfileClicked?: (
    event?: React.KeyboardEvent | React.MouseEvent
  ) => void
  onSettingsClicked?: (event?: React.KeyboardEvent | React.MouseEvent) => void
  translated?: boolean
  version?: Version
}

const Profile: React.FunctionComponent<ProfileProps> = ({
  translated,
  bundledRelease,
  version,
  onViewProfileClicked,
  onSettingsClicked,
}) => {
  const { user, logout } = useAuth0()
  const navigate = useNavigate()
  const [openProfile, setOpenProfile] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const rolesKey = Object.keys(user as any).filter(
    (key) => key.indexOf('roles') !== -1
  )[0]
  const role = user?.[rolesKey].join(', ')

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpenProfile(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div
      className={classNames(
        style.profile,
        openProfile ? style.profileOpen : undefined
      )}
      ref={ref}
    >
      <>
        {!openProfile && (
          <Tooltip
            text={translated ? <FormattedMessage id="profile" /> : 'Profile'}
            position={'left'}
          >
            <img
              src={user?.picture}
              className={classNames(
                style.userPicture,
                version === Version.v2 ? style.userPictureV2 : undefined
              )}
              onClick={(event) =>
                onViewProfileClicked?.(event) || setOpenProfile(!openProfile)
              }
              alt={'user'}
            />
          </Tooltip>
        )}
        {openProfile && (
          <div className={style.dropdownContent}>
            <div className={style.dropdownItem}>
              <img
                src={user?.picture}
                className={style.userPicture}
                alt={'user'}
              />
              <div className={style.userName}>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.large}
                >
                  {user?.given_name
                    ? `${user?.given_name} ${user?.family_name}`
                    : user?.nickname}
                </Typography>
                <Typography
                  className={style.userRole}
                  type={TypographyTypes.label}
                  size={TypographySizes.large}
                >
                  {role}
                </Typography>
              </div>
            </div>
            <>
              <div
                className={classNames(
                  style.dropdownItem,
                  style.dropdownItemLogout,
                  style.dropdownItemBorder
                )}
                onClick={() => {
                  onSettingsClicked?.()
                  setOpenProfile(false)
                  navigate('/settings/account')
                }}
              >
                <Icon name={'Settings'} size={IconSizes.medium} />
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.large}
                >
                  {translated ? <FormattedMessage id="settings" /> : 'Settings'}
                </Typography>
              </div>
              <div
                className={classNames(
                  style.dropdownItem,
                  style.dropdownItemLogout
                )}
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                <Icon name={'Logout'} />
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.large}
                >
                  {translated ? <FormattedMessage id="log_out" /> : 'Log out'}
                </Typography>
              </div>
            </>
            {bundledRelease && (
              <div
                className={classNames(
                  style.dropdownItem,
                  style.dropdownItemVersion,
                  style.dropdownItemBorder
                )}
              >
                <div className={style.release}>
                  <Typography
                    type={TypographyTypes.label}
                    size={TypographySizes.small}
                    styles={TypographyStyles.light}
                  >
                    {translated ? (
                      <FormattedMessage
                        id={'release_version'}
                        values={{ version: bundledRelease.commitHash }}
                      />
                    ) : (
                      `Version: ${bundledRelease.commitHash}`
                    )}
                  </Typography>
                  <Typography
                    type={TypographyTypes.label}
                    size={TypographySizes.small}
                    styles={TypographyStyles.light}
                  >
                    {translated ? (
                      <FormattedMessage
                        id={'release_date'}
                        values={{
                          date: new Date(
                            bundledRelease.releasedAt
                          ).toLocaleString('en-GB'),
                        }}
                      />
                    ) : (
                      `Released on ${new Date(
                        bundledRelease.releasedAt
                      ).toLocaleString('en-GB')}`
                    )}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  )
}

export default Profile
