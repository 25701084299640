import { ReactComponent as LogoIcon } from './images/logoIcon.svg'
import { ReactComponent as DEIcon } from './images/deIcon.svg'
import { ReactComponent as ENIcon } from './images/enIcon.svg'
import { ReactComponent as DKIcon } from './images/dkIcon.svg'
import { ReactComponent as YaakLogoFull } from './images/yaakFullImage.svg'
import { ReactComponent as DESmallIcon } from './images/deSmallIcon.svg'
import { ReactComponent as DKSmallIcon } from './images/dkSmallIcon.svg'
import { ReactComponent as ENSmallIcon } from './images/enSmallIcon.svg'
import { ReactComponent as YaakIcon } from './images/yaakIcon.svg'
import { ReactComponent as MarkerIcon } from './images/marker.svg'

export {
  DEIcon,
  DKIcon,
  DESmallIcon,
  DKSmallIcon,
  ENSmallIcon,
  ENIcon,
  LogoIcon,
  MarkerIcon,
  YaakIcon,
  YaakLogoFull,
}
