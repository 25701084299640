// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__metadataLog--JKEG0 {\n  height: 100%;\n  overflow: auto;\n  padding: 1rem;\n  font-size: 0.75rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MetadataLog/style.less"],"names":[],"mappings":"AAGA;EAEI,YAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;AAHJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n\n:local {\n  .metadataLog {\n    height: 100%;\n    overflow: auto;\n    padding: @spacing-100;\n    font-size: @spacing-75;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"metadataLog": "style__metadataLog--JKEG0"
};
export default ___CSS_LOADER_EXPORT___;
