import React, { memo, ReactNode, useRef, useState } from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon/Icon'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import { ClickAwayListener, Popper } from '@mui/material'
import { SelectMenuItem, ISelectMenuItem } from './SelectMenuItem'

interface NestedSelectProps {
  options: ISelectMenuItem[]
  value: string
  onChange?: (value?: string) => void
  transformValue?: (value: string) => string | ReactNode
}

const NestedSelect = ({
  options,
  value,
  onChange,
  transformValue = (v) => v,
}: NestedSelectProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState(false)

  const toggleDropdown = () => setOpen((v) => !v)
  const closeDropdown = () => setOpen(false)

  const onSelection = (val: string) => {
    closeDropdown()
    onChange?.(val)
  }

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div>
        <button
          ref={buttonRef}
          className={style.selectValue}
          onClick={toggleDropdown}
        >
          <Typography type={TypographyTypes.label}>
            {transformValue(value)}
          </Typography>
          <Icon
            name={open ? 'ArrowDropUp' : 'ArrowDropDown'}
            version={Version.v2}
          />
        </button>

        <Popper
          open={open}
          disablePortal
          anchorEl={buttonRef.current}
          placement="bottom-start"
          style={{ zIndex: 1 }}
        >
          <div className={style.dropdownContainer}>
            {options.map((option) => (
              <SelectMenuItem
                key={option.value}
                {...option}
                selectedValue={value}
                onSelection={onSelection}
              />
            ))}
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default memo(NestedSelect)
