import React, { ReactElement } from 'react'
import ScenariosOverview from '../../components/ScenariosOverview'

interface ScenariosPageProps {
  token: string
}

const ScenariosPage: React.FunctionComponent<ScenariosPageProps> = ({
  token,
}): ReactElement => {
  return <ScenariosOverview token={token} />
}

export default ScenariosPage
