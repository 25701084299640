import React, { useEffect, useRef, useState } from 'react'
import Popover from '@yaak/components/src/Popover'
import Divider from '@yaak/components/src/Divider'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import { Version } from '@yaak/components/src/types'
import style from './style.less'
import { statusDataType } from '../../helpers/drives'
import { FormattedMessage } from 'react-intl'
import { STATUS_CODES } from '../../helpers/statusCodes'

interface DataStatusProps {
  icon: React.ReactNode
  data: statusDataType
  type: number
  dsAdmin: boolean
  version?: Version
  position?: string
}

const labelsMapping = {
  [STATUS_CODES.UPLOADING]: 'Uploading',
  [STATUS_CODES.PROCESSED]: 'Complete',
  [STATUS_CODES.PROCESSING_FAILED]: 'Error',
  [STATUS_CODES.WAITING]: 'Waiting',
  [STATUS_CODES.PROCESSING]: 'In progress',
}

const getLabel = (value?: number) =>
  !value ? 'Not started' : labelsMapping[value]

const getClassName = (value?: number) =>
  !value
    ? style.valueNotStarted
    : value === STATUS_CODES.PROCESSED
    ? style.valueComplete
    : value === STATUS_CODES.PROCESSING_FAILED
    ? style.valueError
    : value === STATUS_CODES.PROCESSING
    ? style.valueInProgress
    : value === STATUS_CODES.UPLOADING || value === STATUS_CODES.WAITING
    ? style.valueUploading
    : undefined

const getClassNameIcon = (value?: number) =>
  !value
    ? style.valueNotStartedIcon
    : value === STATUS_CODES.PROCESSED
    ? style.valueCompleteIcon
    : value === STATUS_CODES.PROCESSING_FAILED
    ? style.valueErrorIcon
    : value === STATUS_CODES.PROCESSING
    ? style.valueInProgressIcon
    : value === STATUS_CODES.UPLOADING || value === STATUS_CODES.WAITING
    ? style.valueUploadingIcon
    : undefined

const getPopoverClassName = (value: number) =>
  value === STATUS_CODES.UPLOADING
    ? style.popoverNotStarted
    : value === STATUS_CODES.PROCESSED
    ? style.popoverCompleted
    : value === STATUS_CODES.PROCESSING_FAILED
    ? style.popoverError
    : value === STATUS_CODES.PROCESSING
    ? style.popoverInProgress
    : undefined

const DataStatus: React.FunctionComponent<DataStatusProps> = ({
  icon,
  data,
  type,
  dsAdmin,
  version,
  position = 'left',
}) => {
  const [open, setOpen] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const iconWrapper = useRef<HTMLDivElement>(null)

  const onScrollFun = () => {
    setOpen(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScrollFun, true)
    return () => window.removeEventListener('scroll', onScrollFun)
  }, [])

  useEffect(() => {
    if (isHovering) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isHovering])

  return (
    <div
      ref={iconWrapper}
      onMouseLeave={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget === iconWrapper.current) {
          setIsHovering(false)
        }
      }}
      onMouseEnter={(event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.currentTarget === iconWrapper.current) {
          setIsHovering(true)
        }
      }}
    >
      <div className={getClassNameIcon(type)}>{icon}</div>
      <div className={style.popoverWrapper}>
        <Popover
          position={position}
          className={getPopoverClassName(type)}
          header={
            dsAdmin ? (
              <FormattedMessage id={'data_processing'} />
            ) : (
              'Data processing'
            )
          }
          content={
            <div className={style.dataStatus}>
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? <FormattedMessage id={'map_data'} /> : 'Metadata'}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.metadataStatus)}
                  version={version}
                >
                  {getLabel(data.metadataStatus)}
                </Typography>
              </div>
              <Divider small />
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? <FormattedMessage id={'video'} /> : 'Raw video'}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.fullVideoStatus)}
                  version={version}
                >
                  {getLabel(data.fullVideoStatus)}
                </Typography>
              </div>
              <Divider small />
              <div className={style.row}>
                <Typography
                  type={TypographyTypes.label}
                  className={style.title}
                  version={version}
                >
                  {dsAdmin ? (
                    <FormattedMessage id={'safety_ai'} />
                  ) : (
                    'Safety AI'
                  )}
                </Typography>
                <Typography
                  type={TypographyTypes.label}
                  className={getClassName(data.safetyAIStatus)}
                  version={version}
                >
                  {getLabel(data.safetyAIStatus)}
                </Typography>
              </div>
            </div>
          }
          open={open}
        />
      </div>
    </div>
  )
}

export default DataStatus
