import { Camera } from '@yaak/components/services/api/api'
import { MosaicParent } from 'react-mosaic-component/src/types'
import {
  CAMERA_POSITIONS,
  CAMERA_POSITIONS_MAP,
} from './stores/VideoPlayerStore'

export const findCamera = (cameras: Camera[], selectedCamera: string) => {
  return cameras.filter((camera) => camera?.name === selectedCamera)[0]
}

export const findCameras = (
  cameraPositions: string[],
  selectedCameras: string[]
): string[] => {
  const camPositions = Object.keys(CAMERA_POSITIONS_MAP).filter(
    (supportedCamera) => cameraPositions.indexOf(supportedCamera) !== -1
  )
  return camPositions.filter((camera) => selectedCameras.indexOf(camera) === -1)
}

export const capitalize = (s: string) =>
  s && s[0].toUpperCase() + s.slice(1).toLowerCase()

export const updateConfig = (
  config: MosaicParent<CAMERA_POSITIONS>,
  treeSize: number,
  camera: string,
  index: number
) => {
  const configSecond =
    typeof config.second === 'string'
      ? {
          second: config.second,
        }
      : {
          ...(config.second as any),
        }
  let newConfig
  if (config.first === 'panel-settings') {
    newConfig =
      treeSize === 1
        ? camera
        : treeSize === 2
        ? index === 0
          ? {
              ...configSecond,
              first: camera,
              second: configSecond.second,
            }
          : {
              ...configSecond,
              first: configSecond.first,
              second: camera,
            }
        : treeSize === 3
        ? index === 0
          ? {
              ...configSecond,
              first: camera,
              second: {
                ...(configSecond.second as Object),
              },
            }
          : index === 1
          ? {
              ...configSecond,
              second: {
                ...(configSecond.second as Object),
                first: camera,
              },
            }
          : {
              ...configSecond,
              second: {
                ...(configSecond.second as Object),
                second: camera,
              },
            }
        : {}
  } else {
    newConfig =
      treeSize === 0
        ? {
            ...config,
            first: camera,
            second: {
              ...configSecond,
            },
          }
        : treeSize === 1
        ? camera
        : treeSize === 2
        ? index === 0
          ? {
              ...config,
              first: camera,
            }
          : {
              ...config,
              second: camera,
            }
        : index === 1
        ? {
            ...config,
            second: {
              ...configSecond,
              first: camera,
            },
          }
        : index === 2
        ? {
            ...config,
            second: {
              ...configSecond,
              second: camera,
            },
          }
        : {
            ...config,
            first: camera,
          }
  }
  return newConfig
}
