import React, { memo, useCallback, useState } from 'react'
import style from './style.less'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import { MergedListKey } from '../SearchQueryBar/SearchQueryBar'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { OPERATORS_MAPPING } from './consts'
import { useSearchParams } from 'react-router-dom'
import { getSearchQueryParams } from './utils'

export interface OperatorSelectProps {
  tag: Record<MergedListKey, string | boolean>
  setOperatorsRef: any
}

const OperatorSelect = ({ tag, setOperatorsRef }: OperatorSelectProps) => {
  const [start, operator] = (tag.value as string).split(' ')
  const [operatorValue, setOperatorValue] = useState(operator)
  const [searchParams, setSearchParams] = useSearchParams()

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const operator = event.target.value
      setOperatorValue(operator)
      const result = getSearchQueryParams({
        searchParams,
        key: tag.key as string,
      })
      if (result) {
        const { params, queryParamToEdit, queryParamRest } = result
        if (queryParamToEdit) {
          const [start, , value] = queryParamToEdit
          const newFullTag = `${start} ${operator} ${value || ''}`
          searchParams.set(
            'q',
            queryParamRest
              ? params
                  .map((param) =>
                    param.replace(/ /g, '') === queryParamToEdit.join('')
                      ? newFullTag
                      : param
                  )
                  .join('&')
              : newFullTag
          )
          setSearchParams(searchParams, { replace: true })
        }
      }
    },
    [searchParams, tag]
  )

  return (
    <Select
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
      }}
      variant="standard"
      value={operatorValue}
      className={style.filterMenuOperatorsSelect}
      MenuProps={{
        ref: setOperatorsRef,
      }}
      IconComponent={(_props) => {
        const opened = _props.className.toString().includes('iconOpen')
        return (
          <Icon
            className={style.iconSelect}
            version={Version.v2}
            name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
          />
        )
      }}
      onChange={onChange}
    >
      {OPERATORS_MAPPING[start]?.map((rate, index) => (
        <MenuItem key={index} value={rate}>
          <Typography
            type={TypographyTypes.body}
            size={TypographySizes.small}
            version={Version.v2}
          >
            {rate}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default memo(OperatorSelect)
