import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import DrivesPage from './pages/DrivesPage'
import ScenariosPage from './pages/ScenariosPage'
import MetricsPage from './pages/MetricsPage'
interface RouterProps {
  token: string
}

function LegacyRedirectToDatasetDrives() {
  const { sessionId } = useParams()
  return <Navigate to={`/dataset/session-logs/${sessionId}`} />
}

const Router: React.FunctionComponent<RouterProps> = ({ token }) => (
  <Routes>
    <Route path="/" element={<Navigate to="/dataset/session-logs/list" />} />
    <Route path="/metrics/" element={<MetricsPage token={token} />} />
    <Route path="/settings/account" element={<div>Settings page</div>} />
    <Route
      path="/dataset/"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    <Route
      path="/dataset/drives"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    <Route
      path="/dataset/session-logs/list"
      element={<DrivesPage token={token} />}
    />
    <Route
      path="/dataset/session-logs/grid"
      element={<DrivesPage token={token} />}
    />
    <Route
      path="/dataset/session-logs/:sessionId"
      element={<Dashboard token={token} />}
    />
    <Route
      path="/dataset/scenarios/list"
      element={<ScenariosPage token={token} />}
    />
    <Route
      path="/dataset/scenarios/grid"
      element={<ScenariosPage token={token} />}
    />
    {/* legacy support: */}
    <Route
      path="/dashboard/"
      element={<Navigate to="/dataset/session-logs/list" />}
    />
    {/* legacy support: */}
    <Route
      path="/dashboard/:sessionId"
      element={<LegacyRedirectToDatasetDrives />}
    />
  </Routes>
)

export default Router
