import { BadgeType } from '@yaak/components/src/Badge/Badge'

const SCORE_COMPETENCY = {
  SERIOUS_ERROR: 15,
  SLIGHT_ERROR: 50,
  ABOVE_AVERAGE: 85,
}

const COLORS_BADGE_MAPPING = {
  [LESS_COLORS['color-green-084']]: BadgeType.green,
  [LESS_COLORS['new-color-red-090']]: BadgeType.red,
  [LESS_COLORS['new-color-orange-090']]: BadgeType.orange,
  [LESS_COLORS['new-color-blue-090']]: BadgeType.blue,
}

export const getSafetyScoreColor = (score: number) =>
  score * 100 >= SCORE_COMPETENCY.ABOVE_AVERAGE
    ? LESS_COLORS['color-green-084']
    : score * 100 <= SCORE_COMPETENCY.SERIOUS_ERROR
    ? LESS_COLORS['new-color-red-090']
    : score * 100 >= SCORE_COMPETENCY.SERIOUS_ERROR &&
      score * 100 <= SCORE_COMPETENCY.SLIGHT_ERROR
    ? LESS_COLORS['new-color-orange-090']
    : LESS_COLORS['new-color-blue-090']

export const getSafetyScoreBadgeType = (score: number) => {
  const color = getSafetyScoreColor(score)
  return COLORS_BADGE_MAPPING[color]
}
