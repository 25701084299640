// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__header--uQQcP {\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n  padding-left: 2rem;\n  height: 3.25rem;\n}\n.style__headerOpacity--_IVZc {\n  opacity: 55%;\n}\n.style__logo--juXI0 {\n  width: 32px;\n  height: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Header/style.less"],"names":[],"mappings":"AAEA;EAEI,eAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,eAAA;AAFJ;AALA;EAWI,YAAA;AAHJ;AARA;EAeI,WAAA;EACA,YAAA;AAJJ","sourcesContent":["@import '@yaak/components/src/spacing';\n\n:local {\n  .header {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    gap: @spacing-100;\n    padding-left: @spacing-200;\n    height: 3.25rem;\n  }\n\n  .headerOpacity {\n    opacity: 55%;\n  }\n\n  .logo {\n    width: 32px;\n    height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style__header--uQQcP",
	"headerOpacity": "style__headerOpacity--_IVZc",
	"logo": "style__logo--juXI0"
};
export default ___CSS_LOADER_EXPORT___;
