import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import {
  DEFAULT_CONTROLS_WITH_CREATION,
  Mosaic,
  MosaicBranch,
  MosaicWindow,
} from 'react-mosaic-component'
import { useShallow } from 'zustand/react/shallow'
import Icon from '@yaak/components/src/Icon/Icon'
import ProgressBar from '@yaak/components/src/ProgressBar'
import style from './style.less'
import { useVideosStore } from '../../stores/VideosStore'
import { Camera } from '@yaak/components/services/api/api'
import {
  CAMERA_POSITIONS,
  PANEL_ID_VIDEO,
  useVideoPlayerStore,
} from '../../stores/VideoPlayerStore'
import { OnProgressProps } from 'react-player/base'
import { usePlayerStore } from '../../stores/PlayerStore'
import Settings from '../../pages/Settings'
import TitleSelect from '../TitleSelect'
import { useOnMountUnsafe } from '../../hooks/useOnMountUnsafe'
import { searchCamerasInLayout } from '../AddPanelSelect/utils'
import Button from '@yaak/components/src/Button/Button'
import { useMetadataStore } from '../../stores/MetadataStore'
import { SafetyScore } from '../../utils/protobufParse'
import { ScalePosition, zoomComponent } from '../../utils/zoomComponent'
import { Version } from '@yaak/components/src/types'

const MAX_BUFFER_LENGTH_SECONDS = 60
const MAX_BUFFER_SIZE_BYTES = 20 * 1024 * 1024
const NUMBER_OF_CAMERAS = 3

const findScore = (scores: SafetyScore[], offset: number) =>
  scores.filter((score) => score.clip.end_timestamp.seconds === offset)

interface VideoPlayerProps {
  token: string
}

interface VideoProps {
  id: string
  path: MosaicBranch[]
  token: string
}

const Video = ({ id, path, token }: VideoProps) => {
  const {
    initialCameras,
    cameras,
    selectedCameras,
    updateInitialCameras,
    updateSelectedCameras,
    updateSelectedCamera,
    session,
  } = useVideosStore(
    useShallow((state) => ({
      initialCameras: state.initialCameras,
      cameras: state.cameras,
      selectedCameras: state.selectedCameras,
      updateInitialCameras: state.updateInitialCameras,
      updateSelectedCameras: state.updateSelectedCameras,
      updateSelectedCamera: state.updateSelectedCamera,
      session: state.session,
    }))
  )
  const { config, update: updateVideoPlayerStore } = useVideoPlayerStore(
    useShallow((state) => ({
      config: state.config,
      update: state.update,
    }))
  )
  const { metadata } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
    }))
  )
  const [safetyScore, setSafetyScore] = useState<SafetyScore>()

  const {
    begin,
    offset,
    jump,
    buffering,
    playing,
    setCameraState,
    setOffset,
    update,
    removeCameraState,
    playbackRate,
  } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      offset: state.offset,
      jump: state.jump,
      buffering: state.buffering,
      update: state.update,
      playing: state.playing,
      setCameraState: state.setCameraState,
      setOffset: state.setOffset,
      removeCameraState: state.removeCameraState,
      playbackRate: state.playbackRate,
    }))
  )
  const playStoreRef = useRef(usePlayerStore.getState().offset)

  const [video, setVideo] = useState<Camera>()
  const [isMain, setIsMain] = useState<boolean>(false)
  const player = useRef<ReactPlayer>(null)
  const playerWrapper = useRef<HTMLDivElement>(null)
  const [pos, setPos] = useState<ScalePosition>({
    scale: 1,
    x: 0,
    y: 0,
  })

  useEffect(() => {
    if (playerWrapper.current) {
      zoomComponent({
        container: playerWrapper.current,
        maxScale: 8,
        factor: 0.1,
        setPos,
      })
    }
  }, [playerWrapper.current])

  useOnMountUnsafe(() => {
    initialCameras.length < NUMBER_OF_CAMERAS &&
      updateInitialCameras([...initialCameras, id])
  })

  useEffect(() => {
    if (metadata.safetyScore) {
      const score = findScore(
        metadata.safetyScore,
        Math.floor(metadata.driveSessionInfo[0]?.time_stamp.seconds + offset)
      )[0]
      setSafetyScore(score || null)
    }
  }, [offset, metadata])

  useEffect(() => {
    if (
      initialCameras.length === NUMBER_OF_CAMERAS &&
      selectedCameras.length === 0
    ) {
      updateSelectedCameras(initialCameras)
    }
  }, [initialCameras])

  useEffect(() => {
    setCameraState(id, { buffering: false })
    usePlayerStore.subscribe((state) => {
      playStoreRef.current = state.offset
    })
  }, [])

  useEffect(
    () => () => {
      removeCameraState(id)
    },
    []
  )

  useEffect(() => {
    setVideo(cameras.filter((v) => v.name === id)[0])
    setIsMain(initialCameras.findIndex((camera) => camera === id) !== -1)
  }, [cameras, id, initialCameras])

  useEffect(() => {
    if (player.current) {
      if (jump !== 0 && isFinite(jump)) {
        player.current.seekTo(jump, 'seconds')
      } else if (begin) {
        player.current.seekTo(begin, 'seconds')
      }
    }
  }, [jump, player.current, begin])

  useEffect(() => {
    if (session.offsetURLStartTimestamp && session.offsetURLEndTimestamp) {
      const playedSeconds =
        new Date(session.startTimestamp).getTime() + offset * 1000
      const startSeconds = new Date(session.offsetURLStartTimestamp).getTime()
      const endSeconds = new Date(session.offsetURLEndTimestamp).getTime()
      if (playedSeconds >= endSeconds || playedSeconds < startSeconds) {
        update({
          playing: false,
        })
      }
    }
  }, [session, offset, update])

  const handleProgress = useCallback(
    (progress: OnProgressProps) => {
      isMain && playing && setOffset(progress.playedSeconds)
    },
    [playing, isMain]
  )

  const additionalControls = (id: string) => (
    <Icon
      name={'Settings'}
      version={Version.v2}
      key={id}
      className={style.settingsIcon}
      onClick={() => {
        config.first === 'panel-settings'
          ? updateVideoPlayerStore({
              ...(config.second as Object),
            })
          : updateVideoPlayerStore({
              direction: 'row',
              first: 'panel-settings',
              second: config,
              splitPercentage: 33,
            })
        updateSelectedCamera(
          selectedCameras.filter((camera) => camera === id)[0]
        )
      }}
    />
  )

  return video?.url ? (
    <MosaicWindow<CAMERA_POSITIONS>
      toolbarControls={[
        additionalControls(id),
        DEFAULT_CONTROLS_WITH_CREATION[3],
      ]}
      path={path}
      title={(<TitleSelect camera={video} />) as any}
      key={id}
      className={style.mosaicWindow}
    >
      {buffering && (
        <div className={style.loadingContainer}>
          <ProgressBar />
        </div>
      )}
      <div className={style.videoWrapper} ref={playerWrapper}>
        <ReactPlayer
          ref={player}
          url={video.url}
          playbackRate={playbackRate}
          progressInterval={100}
          style={{
            transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`,
            transformOrigin: '0px 0px',
          }}
          config={{
            file: {
              forceHLS: true,
              hlsVersion: '1.5.7',
              hlsOptions: {
                maxBufferLength: MAX_BUFFER_LENGTH_SECONDS,
                maxBufferSize: MAX_BUFFER_SIZE_BYTES,
                startPosition: offset,
                maxFragLookUpTolerance: 0,
                xhrSetup: (xhr: XMLHttpRequest) => {
                  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                },
              },
            },
          }}
          onEnded={() => {
            update({ playing: false })
          }}
          playing={playing && !buffering}
          onProgress={handleProgress}
          onBuffer={() => {
            setCameraState(id, { buffering: true })
          }}
          onSeek={() => {
            setCameraState(id, { buffering: false })
          }}
          onBufferEnd={() => {
            setCameraState(id, { buffering: false })
          }}
          onReady={() => {
            setCameraState(id, { buffering: false })
          }}
          onError={console.error}
        />
        {safetyScore?.score && (
          <div className={style.score}>
            <Button
              text={`${(safetyScore.score * 100).toFixed(0)}%`}
              onClick={() => {}}
            />
          </div>
        )}
        {pos.scale > 1 && (
          <div className={style.zoom}>
            <Button
              text={'Reset view'}
              onClick={() =>
                setPos({
                  x: 0,
                  y: 0,
                  scale: 1,
                })
              }
            />
          </div>
        )}
      </div>
    </MosaicWindow>
  ) : (
    <></>
  )
}

const VideoPlayer = ({ token }: VideoPlayerProps) => {
  const config = useVideoPlayerStore(useShallow((state) => state.config))
  const update = useVideoPlayerStore(useShallow((state) => state.update))
  const { selectedCameras, updateSelectedCameras, cameras } = useVideosStore(
    useShallow((state) => ({
      selectedCameras: state.selectedCameras,
      updateSelectedCameras: state.updateSelectedCameras,
      cameras: state.cameras,
    }))
  )

  return cameras?.length > 0 ? (
    <Mosaic<string>
      onChange={(newLayout: any) => {
        if (newLayout?.first === 'panel-settings') {
          update({
            ...(newLayout?.second as Object),
          })
        } else {
          update(newLayout)
        }
        const existingCameras = searchCamerasInLayout(newLayout)
        const updatedCameras = selectedCameras.filter((selected) =>
          existingCameras.includes(selected)
        )
        updateSelectedCameras(updatedCameras)
      }}
      renderTile={(id, path) => {
        return id === 'panel-settings' ? (
          <Settings panelId={PANEL_ID_VIDEO} />
        ) : (
          <Video id={id} path={path} token={token} />
        )
      }}
      blueprintNamespace={'bp4'}
      className={'mosaic-blueprint-theme'}
      initialValue={config}
    />
  ) : (
    <div>There is no video data for this drive</div>
  )
}

export default VideoPlayer
