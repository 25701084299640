import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import style from './style.less'
import Icon from '@yaak/components/src/Icon/Icon'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import { Popper } from '@mui/material'
import classNames from 'classnames'

export interface ISelectMenuItem {
  label: string
  value: string
  icon?: string
  disabled?: boolean
  isSearchable?: boolean
  subMenuItems?: ISelectMenuItem[]
}

interface ISelectMenuItemProps extends ISelectMenuItem {
  selectedValue?: string
  onSelection: (value: string) => void
}

export const SelectMenuItem = ({
  label,
  value,
  icon,
  disabled,
  isSearchable,
  subMenuItems,
  selectedValue,
  onSelection,
}: ISelectMenuItemProps) => {
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const ref = useRef<HTMLDivElement | null>(null)

  const onClick = (val: string) => () => {
    onSelection(val)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const filteredOptions = useMemo(
    () =>
      subMenuItems?.filter(({ label }) =>
        label.toLowerCase().includes(search.toLowerCase())
      ),
    [search, subMenuItems]
  )

  useEffect(() => {
    if (selectedValue) {
      setOpen(
        subMenuItems?.some(({ value }) => selectedValue === value) || false
      )
    }
  }, [])

  return (
    <div
      ref={ref}
      onMouseEnter={
        subMenuItems && subMenuItems?.length > 0
          ? () => setOpen(true)
          : undefined
      }
      onMouseLeave={() => setOpen(false)}
    >
      <span
        className={classNames(
          style.itemContainer,
          open ? style.subItemsOpen : '',
          disabled ? style.disabled : ''
        )}
        onClick={
          !subMenuItems && value && !disabled ? onClick(value) : undefined
        }
      >
        <span className={style.labelContainer}>
          {icon && (
            <Icon
              name={icon}
              version={Version.v2}
              color={disabled ? '' : 'color-neutral-040'}
            />
          )}
          <Typography type={TypographyTypes.label}>{label}</Typography>
        </span>
        {subMenuItems && subMenuItems?.length > 0 && (
          <Icon name="ChevronForward" version={Version.v2} />
        )}
        {selectedValue === value && (
          <Icon name="Checkmark" version={Version.v2} color="color-green-038" />
        )}
      </span>
      {subMenuItems && (
        <Popper
          anchorEl={ref.current}
          disablePortal
          open={open}
          style={{ zIndex: 1 }}
          placement={'right-end'}
        >
          <div className={style.dropdownContainer}>
            {isSearchable && subMenuItems?.length > 10 && (
              <div className={style.searchInput}>
                <Icon name="Search" version={Version.v2} />
                <input
                  placeholder="Search"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            )}
            {filteredOptions?.map((subItem) => (
              <SelectMenuItem
                key={subItem.value}
                {...subItem}
                onSelection={onSelection}
                selectedValue={selectedValue}
              />
            ))}
          </div>
        </Popper>
      )}
    </div>
  )
}
