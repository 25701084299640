// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__doughnut--dR_gS {\n  display: flex;\n  align-self: center;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  padding-left: 1rem;\n}\n.style__horizontalBar--Qaogh {\n  background: #fff;\n  display: flex;\n  border-radius: 1rem;\n  padding: 0.5rem;\n  max-height: 140px;\n}\n.style__bar--KfM_W {\n  align-self: center;\n  display: flex;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/MetricCharts/style.less"],"names":[],"mappings":"AAGA;EAEI,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAHJ;AAJA;EAWI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAJJ;AAXA;EAmBI,kBAAA;EACA,aAAA;EACA,WAAA;AALJ","sourcesContent":["@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/colors';\n\n:local {\n  .doughnut {\n    display: flex;\n    align-self: center;\n    justify-content: center;\n    height: 100%;\n    width: 100%;\n    padding-left: @spacing-100;\n  }\n\n  .horizontalBar {\n    background: @color-white;\n    display: flex;\n    border-radius: @spacing-100;\n    padding: @spacing-50;\n    max-height: 140px;\n  }\n\n  .bar {\n    align-self: center;\n    display: flex;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doughnut": "style__doughnut--dR_gS",
	"horizontalBar": "style__horizontalBar--Qaogh",
	"bar": "style__bar--KfM_W"
};
export default ___CSS_LOADER_EXPORT___;
