import { Dispatch, SetStateAction } from 'react'

export type ScalePosition = {
  scale: number
  x: number
  y: number
}

interface zoomComponentProps {
  container: HTMLDivElement
  maxScale: number
  factor: number
  setPos: Dispatch<SetStateAction<ScalePosition>>
}

export const zoomComponent = ({
  container,
  maxScale,
  factor,
  setPos,
}: zoomComponentProps) => {
  const target = container.querySelector('div')
  const pos = { x: 0, y: 0 }
  const zoomTarget = { x: 0, y: 0 }
  const zoomPoint = { x: 0, y: 0 }
  let scale = 1

  function scrolled(event: any) {
    const offset = container.getBoundingClientRect()
    zoomPoint.x = (event.pageX - offset.left - pos.x) / scale
    zoomPoint.y = (event.pageY - offset.top - pos.y) / scale

    event.preventDefault()
    let delta = event.wheelDelta
    delta = Math.max(-1, Math.min(1, delta))

    zoomTarget.x = (event.pageX - offset.left - pos.x) / scale
    zoomTarget.y = (event.pageY - offset.top - pos.y) / scale

    scale += delta * factor * scale
    scale = Math.max(1, Math.min(maxScale, scale))

    pos.x = -zoomTarget.x * scale + zoomPoint.x
    pos.y = -zoomTarget.y * scale + zoomPoint.y

    setPos({
      scale,
      x: pos.x,
      y: pos.y,
    })
  }

  if (target) {
    target.addEventListener('mousewheel', scrolled)
  }
}
