import React, { ChangeEvent, useMemo, useState } from 'react'
import style from './style.less'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import { Popover, PopoverVirtualElement } from '@mui/material'
import { NestedMenuItem } from 'mui-nested-menu'
import MenuItem from '@mui/material/MenuItem'
import Typography, { TypographySizes, TypographyTypes } from '../Typography'
import {
  SEARCH_ALL_FILTERS_ID,
  SEARCH_TAGS_MAPPING,
  SECOND_LEVEL_FILTER_ITEMS,
  TOP_LEVEL_FILTER_ITEMS,
  TOP_LEVEL_FILTER_ITEMS_ICONS,
} from './consts'

const createMenuItemKey = (name: string) =>
  name.toLowerCase().replace(/\s/g, '_')

interface FilterMenuItemProps {
  mapping?: Record<string, string[]>
  text: string
  onClick?: (value: string) => void
}

const FilterMenuItem = ({ text, mapping, onClick }: FilterMenuItemProps) => (
  <div className={style.filterMenuItem} onClick={() => onClick?.(text)}>
    <Typography
      className={style.filterMenuItemText}
      version={Version.v2}
      type={TypographyTypes.label}
    >
      {text}
    </Typography>
    <Typography
      className={style.filterMenuItemText}
      version={Version.v2}
      type={TypographyTypes.label}
    >
      {mapping?.[text]?.length}
    </Typography>
  </div>
)

interface FilterMenuProps {
  onClick: (tag: string | null) => any
  onClearFilters: () => void
  showClearAction: boolean
}

const FilterMenu = ({
  showClearAction,
  onClick,
  onClearFilters,
}: FilterMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<
    | Element
    | (() => Element)
    | PopoverVirtualElement
    | (() => PopoverVirtualElement)
    | null
    | undefined
  >(null)
  const open = Boolean(anchorEl)
  const [search, setSearch] = useState<string>('')

  const onIconClick = (e: MouseEvent) => setAnchorEl(e.currentTarget as Element)

  const onClose = () => {
    setAnchorEl(null)
    setSearch('')
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const filteredItems: Record<string, string[]> = useMemo(
    () =>
      Object.entries(SECOND_LEVEL_FILTER_ITEMS).reduce(
        (acc, [category, items]) => {
          const filtered = items.filter((item) =>
            item.toLowerCase().includes(search.toLowerCase())
          )
          if (search && filtered.length > 0) {
            return { ...acc, [category]: filtered }
          }
          return acc
        },
        {}
      ),
    [search]
  )

  return (
    <div>
      <button>
        <Icon
          name={'Filter'}
          version={Version.v2}
          className={style.filterButton}
          onClick={onIconClick}
        />
      </button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        sx={{ '& .MuiPopover-paper': { padding: '8px 0' } }}
        className={style.filterMenu}
      >
        <div className={style.searchContainer}>
          <div className={style.searchInput}>
            <Icon name="Search" version={Version.v2} />
            <input
              id={SEARCH_ALL_FILTERS_ID}
              placeholder="Search all"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {search && (
            <Icon
              name="Close"
              version={Version.v2}
              className={style.closeIcon}
              onClick={() => setSearch('')}
            />
          )}
        </div>
        {!search &&
          TOP_LEVEL_FILTER_ITEMS.map((topLevel) => (
            <NestedMenuItem
              leftIcon={
                <Icon
                  name={TOP_LEVEL_FILTER_ITEMS_ICONS[topLevel]}
                  version={Version.v2}
                />
              }
              key={createMenuItemKey(topLevel)}
              label={
                (
                  <FilterMenuItem
                    text={topLevel}
                    mapping={SECOND_LEVEL_FILTER_ITEMS}
                  />
                ) as any
              }
              parentMenuOpen={open}
            >
              {SECOND_LEVEL_FILTER_ITEMS[topLevel].map((secondLevel) => (
                <MenuItem
                  onClick={onClose}
                  key={createMenuItemKey(secondLevel)}
                >
                  <FilterMenuItem
                    text={secondLevel}
                    mapping={SECOND_LEVEL_FILTER_ITEMS}
                    onClick={(value) => {
                      onClick(
                        SEARCH_TAGS_MAPPING[value]
                          ? SEARCH_TAGS_MAPPING[value]
                          : null
                      )
                    }}
                  />
                </MenuItem>
              ))}
            </NestedMenuItem>
          ))}
        {search && (
          <div className={style.filteredContainer}>
            {Object.entries(filteredItems).map(([category, items]) => (
              <>
                <Typography
                  type={TypographyTypes.label}
                  size={TypographySizes.small}
                  className={style.category}
                >
                  {category}
                </Typography>
                {items.map((item) => (
                  <MenuItem key={createMenuItemKey(item)} onClick={onClose}>
                    <FilterMenuItem
                      text={item}
                      mapping={SECOND_LEVEL_FILTER_ITEMS}
                      onClick={(value) => {
                        onClick(
                          SEARCH_TAGS_MAPPING[value]
                            ? SEARCH_TAGS_MAPPING[value]
                            : null
                        )
                      }}
                    />
                  </MenuItem>
                ))}
              </>
            ))}
          </div>
        )}
        {showClearAction && (
          <div className={style.clearButtonContainer}>
            <button className={style.clearButton} onClick={onClearFilters}>
              <Typography type={TypographyTypes.label}>
                Clear filters
              </Typography>
            </button>
          </div>
        )}
      </Popover>
    </div>
  )
}

export default FilterMenu
