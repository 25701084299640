import React, { useEffect, useState } from 'react'
import style from './style.less'
import {
  Scenario,
  SearchSessionData,
  SessionData,
} from '@yaak/components/services/api/api'
import SortingSelect, { ISortingSelectProps } from './SortingSelect'
import { onScroll } from '../Grid/helpers'
import GridItem from './GridItem'
import Toast from '../Toast'
import { toastType } from '../Toast/Toast'

interface IGridViewProps extends ISortingSelectProps {
  token: string
  sessionsData: SessionData[] | Scenario[] | SearchSessionData[]
  fetchData?: () => void
}
const GridView = ({
  token,
  sessionsData,
  onSort,
  sortBy,
  sortOrder,
  fetchData,
}: IGridViewProps) => {
  const [loadingMore, setLoadingMore] = useState(false)
  const [toast, setShowToast] = useState<toastType | null>(null)

  useEffect(() => {
    const containerElem = document.querySelector('#gridViewContainer')
    const lastItemElem = document.querySelector('.gridViewItem:last-of-type')
    if (fetchData && containerElem && lastItemElem) {
      const onScrollFun = () =>
        onScroll({
          fetchData,
          loadingMore,
          setLoadingMore,
          containerElem,
          lastItemElem,
        })
      window.addEventListener('scroll', onScrollFun, true)
      return () => window.removeEventListener('scroll', onScrollFun)
    }
  }, [sessionsData?.length, fetchData, loadingMore])

  return (
    <div className={style.container} id="gridViewContainer">
      <SortingSelect onSort={onSort} sortBy={sortBy} sortOrder={sortOrder} />
      <div className={style.gridContainer}>
        {sessionsData?.map((session) => (
          <GridItem
            key={
              (session as SessionData).id ||
              `${(session as Scenario).sessionId}-${session.startTimestamp}`
            }
            session={session}
            token={token}
            setShowToast={setShowToast}
          />
        ))}
      </div>
      {toast && <Toast toast={toast} setShowToast={setShowToast} />}
    </div>
  )
}

export default GridView
