import { ChartXY } from '@lightningchart/lcjs'

interface GetPixelForXValueProps {
  xValue: number
  min: number
  max: number
  left: number
  right: number
}

export const getPixelForXValue = ({
  xValue,
  min,
  max,
  left,
  right,
}: GetPixelForXValueProps): number | undefined => {
  if (xValue === undefined) {
    return undefined
  }

  const pixelRange = right - left
  if (pixelRange <= 0) {
    return undefined
  }

  if (xValue < min || xValue > max) {
    return undefined
  }

  return left + ((xValue - min) / (max - min)) * pixelRange
}

interface GetXValueAtPixelProps {
  pixelX: number
  min: number
  max: number
  left: number
  right: number
}

export const getXValueAtPixel = ({
  pixelX,
  min,
  max,
  left,
  right,
}: GetXValueAtPixelProps): number => {
  const pixelRange = right - left
  if (pixelRange <= 0) {
    return -1
  }

  return min + ((pixelX - left) / pixelRange) * (max - min)
}

// TODO: Figure out what functions we can use instead those weird chart properties
// @ts-ignore
export const getChartAxisXMin = (chart: ChartXY) => chart.axisX?.$h
// @ts-ignore
export const getChartAxisXMax = (chart: ChartXY) => chart.axisX?.Xh
// @ts-ignore
export const getChartLeftPosition = (chart: ChartXY) => chart.Yk?.left

export const getChartRightPosition = (chart: ChartXY) =>
  chart.engine.container.offsetWidth
