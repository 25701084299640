import { create } from 'zustand'
import { MosaicParent } from 'react-mosaic-component/src/types'

export const PANEL_ID_CHART = 'CHART'
export const PANEL_ID_METADATA = 'METADATA'
export const PANEL_ID_MAP = 'MAP'

export type LAYOUT_NODE =
  | 'panel-videos'
  | 'panel-metadata'
  | 'panel-map'
  | 'panel-plot'
  | 'panel-settings'
interface MosaicStoreState {
  config: MosaicParent<LAYOUT_NODE>
  update: (state: any) => void
}

const CONFIG: MosaicParent<LAYOUT_NODE> = {
  direction: 'column',
  first: 'panel-videos',
  second: {
    direction: 'row',
    first: 'panel-map',
    second: {
      direction: 'row',
      first: 'panel-metadata',
      second: 'panel-plot',
    },
    splitPercentage: 33,
  },
  splitPercentage: 50,
}

export const useMosaicStore = create<MosaicStoreState>((set) => ({
  config: CONFIG,
  update: (change) => {
    set((state) => {
      return typeof change === 'string'
        ? {
            config: change,
          }
        : {
            config:
              typeof state.config === 'string'
                ? {
                    ...change,
                  }
                : {
                    ...(state.config as Object),
                    ...change,
                  },
          }
    })
  },
}))
