import { MosaicNode, updateTree } from 'react-mosaic-component'

export const createMosaicStoreTree = (
  config: MosaicNode<any>,
  value: string
) => {
  let tree
  if (typeof config === 'string') {
    tree = {
      direction: 'column',
      first: 'panel-videos',
      second: value,
      splitPercentage: 50,
    }
  } else {
    if (
      value === 'panel-map' ||
      value === 'panel-metadata' ||
      value === 'panel-chart'
    ) {
      tree = updateTree(config, [
        {
          path: ['second'],
          spec: {
            $set:
              value === 'panel-map'
                ? typeof config === 'string'
                  ? {
                      direction: 'row',
                      first: 'panel-map',
                    }
                  : {
                      direction: 'row',
                      first: 'panel-map',
                      second:
                        typeof config.second === 'string'
                          ? config.second
                          : {
                              ...config.second,
                            },
                      splitPercentage: 33,
                    }
                : value === 'panel-metadata'
                ? {
                    direction: 'row',
                    first: config.second.first || 'panel-metadata',
                    second:
                      typeof config.second === 'string'
                        ? config.second
                        : {
                            ...config.second,
                            first: 'panel-metadata',
                            splitPercentage: 50,
                          },
                    splitPercentage: 33,
                  }
                : {
                    direction: 'row',
                    first: config.second.first || 'panel-chart',
                    second:
                      typeof config.second === 'string'
                        ? config.second
                        : {
                            ...config.second,
                            first: config.second.second,
                            second: 'panel-chart',
                            splitPercentage: 50,
                          },
                    splitPercentage: 33,
                  },
          },
        },
      ])
    }
  }
  return tree
}

export const createVideoConfigTree = (
  config: MosaicNode<any>,
  camera?: string
) => {
  let tree
  if (!config) {
    tree = 'CAM_FRONT_CENTER'
  } else if (typeof config === 'string') {
    tree = {
      direction: 'row',
      first: 'CAM_REAR',
      second: 'CAM_FRONT_CENTER',
      splitPercentage: 50,
    }
  } else {
    tree = updateTree(config, [
      {
        path: [],
        spec: {
          $set: {
            ...config,
            second: {
              direction: 'row',
              first: config.second,
              second: camera || 'CAM_LEFT_BACKWARD',
            },
            splitPercentage: 33,
          },
        },
      },
    ])
  }
  if (config?.second?.second) {
    tree = updateTree(config, [
      {
        path: [],
        spec: {
          $set: {
            ...config,
            second: {
              ...config.second,
              splitPercentage: 33,
            },
            splitPercentage: 25,
          },
        },
      },
      {
        path: ['second', 'second'],
        spec: {
          $set: {
            direction: 'row',
            first: config.second.second,
            second: camera || 'CAM_RIGHT_BACKWARD',
            splitPercentage: 50,
          },
        },
      },
    ])
  }
  return tree
}

export const searchCamerasInLayout = (
  layout: MosaicNode<any>,
  result: string[] = []
) => {
  if (typeof layout === 'string') {
    layout.indexOf('CAM') !== -1 && result.push(layout)
  } else if (layout) {
    const values = Object.values(layout)
    values.forEach((value) => {
      if (typeof value === 'object') {
        searchCamerasInLayout(value, result)
      } else {
        typeof value === 'string' &&
          value.indexOf('CAM') !== -1 &&
          result.push(value)
      }
    })
  }
  return result
}
