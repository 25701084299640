import React, { useState } from 'react'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import { Version } from '@yaak/components/src/types'
import style from './style.less'
import Switch from '@yaak/components/src/Switch'
import {
  MapSettings as Settings,
  useMetadataStore,
} from '../../stores/MetadataStore'
import { useShallow } from 'zustand/react/shallow'

interface DataSetLineProps {
  label: string
  color: string
}

const labelsMap: Record<string, string> = {
  'Route tags': 'route_tags',
  Annotations: 'annotations',
}

const DataSetLine = ({ label, color }: DataSetLineProps) => {
  const { mapSettings, updateMapSettings } = useMetadataStore()
  const labelName = labelsMap[label]
  const [checked, setChecked] = useState(
    mapSettings[labelName as keyof Settings]?.display
  )

  return (
    <>
      <div className={style.row}>
        <div className={style.row}>
          <span className={style.dot} style={{ background: color }} />
          <Typography
            className={style.label}
            type={TypographyTypes.label}
            version={Version.v2}
          >
            {label}
          </Typography>
        </div>
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked)
            updateMapSettings({
              ...mapSettings,
              [labelName]: {
                ...mapSettings[labelName as keyof Settings],
                display: !checked,
              },
            })
          }}
        />
      </div>
    </>
  )
}

const MapSettings = () => {
  const { mapSettings } = useMetadataStore(
    useShallow((state) => ({
      mapSettings: state.mapSettings,
    }))
  )
  return (
    <div className={style.settings}>
      <div className={style.row}>
        <Typography
          className={style.label}
          type={TypographyTypes.label}
          size={TypographySizes.small}
          version={Version.v2}
        >
          EVENTS
        </Typography>
      </div>
      <DataSetLine
        label={'Annotations'}
        color={mapSettings.annotations.color}
      />
      <DataSetLine label={'Route tags'} color={mapSettings.route_tags.color} />
    </div>
  )
}

export default MapSettings
