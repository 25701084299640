// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__namePlate--oVpvG {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1rem;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.style__namePlateHover--EyKAR:hover {\n  text-decoration: underline;\n}\nimg {\n  border-radius: 50%;\n  height: 1.5rem;\n  width: 1.5rem;\n}\n.style__ellipsis--_BnTP {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n@media only screen and (max-width: 1140px) {\n  .style__ellipsis--_BnTP {\n    width: 60px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./../components/src/AvatarNamePlate/style.less"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAPA;EAaI,0BAAA;AAHJ;AAVA;EAiBI,kBAAA;EACA,cAAA;EACA,aAAA;AAJJ;AAfA;EAuBI,gBAAA;EACA,mBAAA;EACA,uBAAA;AALJ;AAQE;EAAA;IAEI,WAAA;EANJ;AACF","sourcesContent":["@import '../spacing';\n\n:local {\n  .namePlate {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n    gap: @spacing-100;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  .namePlateHover:hover {\n    text-decoration: underline;\n  }\n\n  img {\n    border-radius: 50%;\n    height: @spacing-150;\n    width: @spacing-150;\n  }\n\n  .ellipsis {\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  @media only screen and (max-width: 1140px) {\n    .ellipsis {\n      width: 60px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"namePlate": "style__namePlate--oVpvG",
	"namePlateHover": "style__namePlateHover--EyKAR",
	"ellipsis": "style__ellipsis--_BnTP"
};
export default ___CSS_LOADER_EXPORT___;
