import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import Grid from '@yaak/components/src/Grid'
import ProgressBar from '@yaak/components/src/ProgressBar'
import Toast from '@yaak/components/src/Toast'
import { toastType } from '@yaak/components/src/Toast/Toast'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Typography from '@yaak/components/src/Typography'
import { TypographyTypes } from '@yaak/components/src/Typography/Typography'
import Divider from '@yaak/components/src/Divider'
import { useSmallScreenMatches } from '../../customHooks/useSmallScreenMatches'
import Empty from '../Empty'
import {
  getMappedDrives,
  getNutronHeaders,
  getScenariosHeaders,
  HEADERS,
} from '@yaak/admin/src/helpers/drives'
import {
  getActiveSessions,
  getSessions,
  Scenario,
  ScenarioData,
  scenariosSearch,
  SearchSession,
  Session,
  SessionData,
  sessionsSearch,
} from '@yaak/components/services/api/api'
import SearchFilter from '../SearchFilter'
import SearchQueryBar from '../SearchQueryBar'
import { useAuth0 } from '@auth0/auth0-react'
import { User as Auth0User } from '@auth0/auth0-spa-js/dist/typings/global'
import { parseSearchParams } from './utils'
import { Version } from '../types'
import GridView from '@yaak/components/src/GridView/GridView'
import { ViewTypeValues } from '../SearchQueryBar/SearchQueryBar'

const headerSort = [
  'Time',
  'Partner',
  'Type',
  'Vehicle',
  'Kit ID',
  'Driver',
  'Instructor',
]

const sortValue = [
  'startTimestamp',
  'partnerName',
  'driveType',
  'vin',
  'dongleId',
  'driverName',
  'instructorName',
]

const headerSortNutron = [
  'Incidents',
  'Map features',
  'Lighting',
  'Weather',
  'Type',
  'Kit ID',
  'Driver',
]

const sortValueNutron = [
  '',
  'incidentCount',
  'curriculumCount',
  'lighting',
  'weather',
  'driveType',
  '',
  'driver',
  '',
  '',
  'dongleId',
]

interface SessionFilter {
  partnerId?: string
  userId?: string
  searchQuery?: string
  vin?: string
  dongleId?: string
  driveType?: number
  type?: string
}

interface DrivesOverviewProps {
  token: string
  filter?: SessionFilter
  withHeader?: boolean
  withInstructor?: boolean
  withPartner?: boolean
  withKit?: boolean
  dsAdmin?: boolean
  withDriver?: boolean
  withVehicle?: boolean
  fixedColumns?: number
  nutron?: boolean
  scenarios?: any
  searchQueryEnabled?: boolean
  version?: Version
  onClick?: (sessionId: string, begin?: number, end?: number) => void
}

interface dateRange {
  sessionStart: string | undefined
  sessionEnd: string | undefined
}

const getUserRole = (auth0User?: Auth0User) => {
  const rolesKey = Object.keys(auth0User as any).filter(
    (key) => key.indexOf('roles') !== -1
  )[0]
  return auth0User?.[rolesKey].join(', ')
}

const DrivesOverview: React.FunctionComponent<DrivesOverviewProps> = ({
  token,
  filter = {},
  withHeader = true,
  withInstructor = true,
  withPartner = true,
  withKit = true,
  withDriver = true,
  withVehicle = true,
  fixedColumns = 0,
  dsAdmin = false,
  nutron = false,
  scenarios,
  searchQueryEnabled = false,
  version,
  onClick,
}): ReactElement => {
  const [loading, setLoading] = useState(true)
  const [interval, setIntervalValue] = useState<NodeJS.Timeout>()
  const [sessions, setSessions] = useState<
    Session | ScenarioData | SearchSession
  >({} as Session)
  const [mappedDrives, setMappedDrives] = useState<any>()
  const [activeSessions, setActiveSessions] = useState<Session>({} as Session)
  const [toast, setShowToast] = useState<toastType | null>(null)
  const [fetchMoreData, setFetchMoreData] = useState<boolean>(false)
  const [sortOrder, setSortOrder] = useState<string>('DESC')
  const [sortBy, setSortBy] = useState<string>()
  const [view, setView] = useState<string>(ViewTypeValues.list)
  const [searchQuery, setSearchQuery] = useState('')
  const [dateRange, setDateRange] = useState<dateRange | null>({
    sessionStart: undefined,
    sessionEnd: undefined,
  })
  const [totalSessions, setTotalSessions] = useState<number | undefined>()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const smallScreenMatches = useSmallScreenMatches()
  const { user } = useAuth0()

  const navigate = useNavigate()
  const sessionsLoadedRef = useRef<number>(0)

  const searchSessions = !scenarios && !!searchParams.getAll('q')[0]

  useEffect(() => {
    const viewType = location.pathname.split('/').pop()
    setView(viewType || ViewTypeValues.list)
  }, [location.pathname])

  const fetchSearchSessions = async (
    params: string,
    begin: string,
    end: string
  ) => {
    const splitParams = params.split('&')
    const data = parseSearchParams(splitParams)
    const sessions = await sessionsSearch({
      token,
      ...(sortBy ? { sortBy, sortOrder } : {}),
      search: {
        ...data,
        range: {
          from: begin,
          to: end,
        },
      },
      onAlert: setShowToast,
    })
    if (sessions) {
      setSessions(sessions)
      setTotalSessions(sessions.metaData.count)
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchSessions = async () => {
      const params = searchParams.getAll('q')[0]
      const begin = searchParams.getAll('begin')[0]
      const end = searchParams.getAll('end')[0]
      const validParams =
        decodeURIComponent(params)
          ?.split(/[&=><!]+/)
          .filter((text) => text !== ' ').length %
          2 ===
        0
      const sessions = !params
        ? await getSessions({
            token,
            sortBy,
            sortOrder,
            partnerId: filter.partnerId,
            userId: filter.userId,
            searchQuery: filter.searchQuery,
            vin: filter.vin,
            dongleId: filter.dongleId,
            driveType: filter.driveType,
            type: filter.type,
            onAlert: setShowToast,
          })
        : validParams &&
          (await fetchSearchSessions(decodeURIComponent(params), begin, end))

      const activeSessions = await getActiveSessions({
        token,
        onAlert: setShowToast,
      })
      setActiveSessions(activeSessions)
      if (sessions) {
        setSessions(sessions)
        setTotalSessions(sessions.metaData.totalCount)
      }
      setLoading(false)
    }

    token && !scenarios && fetchSessions()
  }, [token, sortBy, sortOrder, filter.userId, scenarios, searchParams])

  useEffect(() => {
    const params = decodeURIComponent(searchParams.getAll('q')[0])
    const begin = searchParams.getAll('begin')[0]
    const end = searchParams.getAll('end')[0]
    const validParams =
      params
        .split(/[&=><!]+/)
        .filter((text) => text !== ' ' && text !== 'date ').length %
        2 ===
      0
    const fetchScenarios = async (params: string) => {
      const splitParams = params
        .split('&')
        .filter((text) => !text.includes('date'))
      const data = parseSearchParams(splitParams)
      const scenariosData = await scenariosSearch({
        token,
        ...(sortBy ? { sortBy, sortOrder } : {}),
        search: {
          ...data,
          range: {
            from: begin,
            to: end,
          },
        },
        onAlert: setShowToast,
      })
      setSessions({
        data: scenariosData,
        metaData: {
          count: 0,
          totalCount: 0,
        },
      })
      setLoading(false)
    }

    token && scenarios && params && validParams && fetchScenarios(params)
  }, [token, scenarios, searchParams, sortBy, sortOrder])

  useEffect(() => {
    const fetchSessions = async () => {
      const sessions = await getSessions({
        token,
        sortBy,
        sortOrder,
        partnerId: filter.partnerId,
        userId: filter.userId,
        onAlert: setShowToast,
        searchQuery,
        ...dateRange,
        vin: filter.vin,
        dongleId: filter.dongleId,
        driveType: filter.driveType,
        type: filter.type,
      })

      setSessions(sessions)
      setTotalSessions(sessions.metaData.totalCount)
    }

    if (!scenarios && !nutron && searchQuery !== null) {
      const timeout = setTimeout(() => {
        token && fetchSessions()
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [token, searchQuery, dateRange, sortBy, scenarios, nutron])

  useEffect(() => {
    ;(!nutron || !scenarios) &&
      token &&
      setIntervalValue(
        setInterval(async () => {
          const activeSessions = await getActiveSessions({
            token,
            onAlert: setShowToast,
          })
          setActiveSessions(activeSessions)
        }, 5000)
      )

    return () => clearInterval(interval)
  }, [token, nutron, scenarios])

  useEffect(() => {
    if (sessions?.data?.length > 0) {
      const mappedDrives = getMappedDrives({
        sessionsData: sessions?.data as SessionData[],
        activeSessionsData: activeSessions?.data,
        setShowToast,
        totalCount: sessions.metaData.totalCount,
        matches: smallScreenMatches,
        navigate,
        options: {
          withPartner,
          withInstructor,
          fixedColumns: searchSessions ? 1 : fixedColumns,
          withKit,
          dsAdmin,
          withDriver,
          withVehicle,
          nutron,
          scenarios,
          searchSessions,
          yaakAdmin: getUserRole(user).indexOf('yaak-admin') !== -1,
        },
      })
      setMappedDrives(mappedDrives)
    }
  }, [sessions?.data, activeSessions?.data, smallScreenMatches, searchSessions])

  useEffect(() => {
    if (fetchMoreData) {
      if (!scenarios) {
        fetchData2()
      }
    }
    setFetchMoreData(false)
  }, [fetchMoreData])

  const fetchData = () => {
    setFetchMoreData(true)
  }
  const fetchData2 = async () => {
    const morePages =
      sessions.data.length &&
      sessions.data.length < sessions.metaData.totalCount

    if (morePages && sessionsLoadedRef.current !== sessions.data.length) {
      sessionsLoadedRef.current = sessions.data.length
      const newSessionPage = await getSessions({
        token,
        onAlert: setShowToast,
        offset: sessions.data.length,
        sortBy,
        sortOrder,
        searchQuery,
        partnerId: filter.partnerId,
        userId: filter.userId,
        ...dateRange,
        dongleId: filter.dongleId,
        vin: filter.vin,
        driveType: filter.driveType,
        type: filter.type,
      })
      if (newSessionPage) {
        setSessions({
          ...sessions,
          data: [...sessions.data, ...newSessionPage.data] as SessionData[],
        })
        setFetchMoreData(false)
      }
    }
  }

  const onRowClick = (rowIndex: number) => {
    const id =
      scenarios || searchSessions
        ? (sessions.data[rowIndex] as Scenario).sessionId
        : (sessions.data[rowIndex] as SessionData).id
    scenarios
      ? onClick?.(
          id,
          (sessions.data[rowIndex] as Scenario).startOffset,
          (sessions.data[rowIndex] as Scenario).endOffset
        )
      : nutron
      ? onClick?.(id)
      : !dsAdmin && navigate(`/drives/${id}`)
  }

  const onHeaderClicked = (props: any) => {
    if (props.sortOrder[props.index] !== null) {
      setSortBy(nutron ? sortValueNutron[props.index] : sortValue[props.index])
      setSortOrder(props.sortOrder[props.index] ? 'DESC' : 'ASC')
    }
  }

  const onGridViewSort = useCallback((sortBy: string, order: string) => {
    setSortBy(sortBy)
    setSortOrder(order)
  }, [])

  const onViewChange = useCallback(
    (value: string) => {
      setView(value)
      const viewType = location.pathname.split('/').pop() || ''
      const path = location.pathname.replace(viewType, value)
      navigate(`${path}${location.search}`)
    },
    [location]
  )

  return (
    <div className={withHeader ? style.overview : style.overviewWithoutHeader}>
      <Typography
        type={TypographyTypes.headline}
        className={!withHeader ? style.hide : undefined}
      >
        {'Drives'}
      </Typography>
      <Divider className={!withHeader ? style.hide : undefined} />
      {searchQueryEnabled ? (
        <SearchQueryBar
          token={token}
          view={view}
          onViewChange={onViewChange}
          totalSessions={totalSessions}
        />
      ) : (
        <SearchFilter
          setSearchQuery={setSearchQuery}
          setDateRange={setDateRange}
        />
      )}
      <div
        className={
          withHeader
            ? style.gridContainer
            : nutron || scenarios
            ? style.userGridContainerNutron
            : style.userGridContainer
        }
      >
        {loading ? (
          <ProgressBar />
        ) : sessions?.data?.length > 0 && mappedDrives?.rows?.length > 0 ? (
          view === ViewTypeValues.list ? (
            <Grid
              data={mappedDrives}
              fetchData={fetchData}
              headerSort={
                scenarios ? [] : nutron ? headerSortNutron : headerSort
              }
              onHeaderClicked={onHeaderClicked}
              onRowClick={onRowClick}
              version={version}
              headers={
                scenarios
                  ? getScenariosHeaders()
                  : nutron
                  ? getNutronHeaders(
                      getUserRole(user).indexOf('yaak-admin') !== -1
                    )
                  : HEADERS
              }
            />
          ) : (
            <GridView
              token={token}
              sessionsData={sessions?.data}
              onSort={onGridViewSort}
              fetchData={fetchData}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
          )
        ) : searchQuery || searchParams.get('q') ? (
          <Empty
            header={'Looks like no scenarios match your query.'}
            content={'Try changing the parameters to get more results.'}
          />
        ) : (
          <Empty
            header={'Whoops! Looks like there are no drives yet.'}
            content={
              'Drives will appear as soon as partners start expert and student drives or internal test drives are conducted.'
            }
          />
        )}
      </div>
      {toast && <Toast toast={toast} setShowToast={setShowToast} />}
    </div>
  )
}

export default DrivesOverview
