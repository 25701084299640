// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__clearIcon--NZX0H {\n  cursor: pointer;\n}\n.style__searchBar--euVqm {\n  height: 3rem;\n  border-radius: 0.5rem;\n  display: flex;\n  border: 1px solid rgba(0, 0, 0, 0.12);\n  padding: 0.7rem;\n  align-items: center;\n  color: #7d8287;\n  font-feature-settings: 'cv01', 'cv09', 'case', 'ss04';\n}\n.style__searchBar--euVqm > input {\n  color: #7d8287 !important;\n  font-size: 1rem;\n  width: 100%;\n}\n.style__searchBar--euVqm:focus-within {\n  outline: 1px solid rgba(0, 0, 0, 0.4);\n}\n.style__searchBar--euVqm input {\n  margin-left: 0.7rem;\n  border: 0;\n}\n.style__searchBar--euVqm input:focus-visible {\n  outline: none;\n}\n.style__searchBarFull--pnpTD {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/SearchBar/style.less"],"names":[],"mappings":"AAGA;EAEI,eAAA;AAHJ;AACA;EAMI,YAAA;EACA,qBAAA;EACA,aAAA;EACA,qCAAA;EACA,eAAA;EACA,mBAAA;EACA,cAAA;EACA,qDAAA;AAJJ;AATA;EAgBM,yBAAA;EACA,eAAA;EACA,WAAA;AAJN;AAOI;EACE,qCAAA;AALN;AAjBA;EA0BM,mBAAA;EACA,SAAA;AANN;AAOM;EACE,aAAA;AALR;AASI;EACE,WAAA;AAPN","sourcesContent":["@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n\n:local {\n  .clearIcon {\n    cursor: pointer;\n  }\n\n  .searchBar {\n    height: @spacing-300;\n    border-radius: @spacing-50;\n    display: flex;\n    border: 1px solid rgba(0, 0, 0, 0.12);\n    padding: 0.7rem;\n    align-items: center;\n    color: @color-asphalt-grey-050;\n    font-feature-settings: 'cv01', 'cv09', 'case', 'ss04';\n\n    > input {\n      color: @color-asphalt-grey-050 !important;\n      font-size: @spacing-100;\n      width: 100%;\n    }\n\n    &:focus-within {\n      outline: 1px solid rgba(0, 0, 0, 0.4);\n    }\n\n    input {\n      margin-left: 0.7rem;\n      border: 0;\n      &:focus-visible {\n        outline: none;\n      }\n    }\n\n    &Full {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clearIcon": "style__clearIcon--NZX0H",
	"searchBar": "style__searchBar--euVqm",
	"searchBarFull": "style__searchBarFull--pnpTD"
};
export default ___CSS_LOADER_EXPORT___;
