import style from './style.less'
import { toFont } from 'chart.js/helpers'
import { Chart, TooltipModel } from 'chart.js'

interface TooltipContext {
  chart: Chart
  replay: boolean
  tooltip: TooltipModel<any>
}

export const renderExternalTooltip = (context: TooltipContext) => {
  let tooltipEl = document.getElementById('chartjsTooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjsTooltip'
    tooltipEl.innerHTML = '<table></table>'
    document.body.appendChild(tooltipEl)
  }

  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  if (tooltipModel.body) {
    const bodyLines = tooltipModel.body.map((body) => body.lines)

    let innerHtml = '<thead>'
    innerHtml += '</thead><tbody>'

    bodyLines.forEach((body, i: number) => {
      const colors = tooltipModel.labelColors[i]
      let styles = 'background:' + colors.backgroundColor
      styles += '; border:1px solid #FFF'
      styles += '; width: 14px; height: 14px;'
      const box = '<div style="' + styles + '"></div>'
      innerHtml += `<tr><td class=${style.tooltipText}>${box}<div>${body}</div></td></tr>`
    })
    innerHtml += '</tbody>'

    const tableRoot = tooltipEl.querySelector('table')
    if (tableRoot) {
      tableRoot.innerHTML = innerHtml
    }
  }

  const position = context.chart.canvas.getBoundingClientRect()

  const bodyFont = toFont(tooltipModel.options.bodyFont as any)

  tooltipEl.classList.add(style.tooltip)

  tooltipEl.style.left =
    position.left + tooltipModel.caretX - tooltipModel.width + 10 + 'px'
  tooltipEl.style.top = position.top + tooltipModel.caretY + 10 + 'px'
  tooltipEl.style.font = bodyFont.string
  tooltipEl.style.opacity = '1'
  tooltipEl.style.pointerEvents = 'none'
}
