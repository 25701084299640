import React, {
  createRef,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import { useMetadataStore } from '../../stores/MetadataStore'
import styles from './style.less'
import {
  calculateAcceleration,
  Metadata,
  Timestamp,
  TurnSignal,
} from '../../utils/protobufParse'
import { FixedSizeList as List } from 'react-window'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { useVideosStore } from '../../stores/VideosStore'

interface MetadataLogProps {}

const ITEM_HEIGHT = 300

const findData = (data: any[], timestamp: Timestamp) =>
  data?.filter((d) => d.time_stamp.seconds === timestamp.seconds)[0]

const convertPositionCovariance = (positionCovariance: number[]) =>
  positionCovariance.map((pc) => pc / 100)

const filterData = (data: any, start: number, end: number) =>
  data.filter((d: any) => {
    return d.time_stamp?.seconds >= start && d.time_stamp?.seconds <= end
  })

const filterSafetyScoreData = (data: any, start: number, end: number) =>
  data.filter(
    (d: any) =>
      d.clip.start_timestamp?.seconds >= start &&
      d.clip.end_timestamp.seconds <= end
  )

const MetadataLog: React.FunctionComponent<MetadataLogProps> = () => {
  const listRef = createRef<List>()
  const rowRef = createRef<HTMLDivElement>()
  const [metadata, setMetadata] = useState<Metadata>({
    driveSessionInfo: [],
    gnss: [],
    vehicleMotion: [],
    vehicleState: [],
    safetyScore: [],
    way: [],
    curriculumPoint: [],
    curriculumLineString: [],
  })
  const {
    url,
    seconds,
    updateMetadata,
    updateSeconds,
    settings,
    updateMetadataLoadingFinished,
    loadingFinished,
  } = useMetadataStore(
    useShallow((state) => ({
      url: state.url,
      seconds: state.seconds,
      updateMetadata: state.updateMetadata,
      updateSeconds: state.updateSeconds,
      settings: state.metadataSettings,
      updateMetadataLoadingFinished: state.updateMetadataLoadingFinished,
      loadingFinished: state.loadingFinished,
    }))
  )
  const { begin, update, offset, context, end } = usePlayerStore(
    useShallow((state) => ({
      begin: state.begin,
      end: state.end,
      update: state.update,
      offset: state.offset,
      context: state.context,
    }))
  )
  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )
  const [size, setSize] = useState<number>(ITEM_HEIGHT)
  const workerRef = useRef<Worker>()

  useEffect(() => {
    rowRef.current && setSize(rowRef.current.offsetHeight)
  }, [rowRef, settings])

  useEffect(() => {
    if (url && !workerRef.current) {
      const newWorker: Worker = new Worker(
        new URL('./../../utils/worker', import.meta.url)
      )
      newWorker.postMessage({
        url,
        begin:
          begin !== undefined &&
          new Date(session.startTimestamp).getTime() / 1000 + begin - context,
        end:
          end !== undefined &&
          new Date(session.endTimestamp).getTime() / 1000 + end + context,
      })
      workerRef.current = newWorker

      newWorker.onmessage = function (event) {
        if (event.data.log) {
          setMetadata((prevMetadata) => {
            const driveSessionInfo =
              prevMetadata.driveSessionInfo.length === 0
                ? event.data.log.driveSessionInfo
                : prevMetadata.driveSessionInfo

            if (
              begin &&
              session.offsetURLStartTimestamp &&
              session.offsetURLEndTimestamp
            ) {
              const start = Math.floor(
                new Date(session.offsetURLStartTimestamp).getTime() / 1000
              )
              const end = Math.floor(
                new Date(session.offsetURLEndTimestamp).getTime() / 1000
              )

              return {
                driveSessionInfo,
                vehicleMotion: prevMetadata.vehicleMotion.concat(
                  filterData(event.data.log.vehicleMotion, start, end)
                ),
                gnss: prevMetadata.gnss.concat(
                  filterData(event.data.log.gnss, start, end)
                ),
                vehicleState: prevMetadata.vehicleState.concat(
                  filterData(event.data.log.vehicleState, start, end)
                ),
                safetyScore: prevMetadata.safetyScore.concat(
                  filterSafetyScoreData(event.data.log.safetyScore, start, end)
                ),
                way: prevMetadata.way.concat(event.data.log.way),
                start,
                end,
                curriculumPoint: prevMetadata.curriculumPoint.concat(
                  filterData(event.data.log.curriculumPoint, start, end)
                ),
                curriculumLineString: prevMetadata.curriculumLineString.concat(
                  filterData(event.data.log.curriculumLineString, start, end)
                ),
              } as Metadata
            } else {
              return {
                driveSessionInfo,
                vehicleMotion: prevMetadata.vehicleMotion.concat(
                  event.data.log.vehicleMotion
                ),
                gnss: prevMetadata.gnss.concat(event.data.log.gnss),
                vehicleState: prevMetadata.vehicleState.concat(
                  event.data.log.vehicleState
                ),
                safetyScore: prevMetadata.safetyScore.concat(
                  event.data.log.safetyScore
                ),
                way: prevMetadata.way.concat(event.data.log.way),
                curriculumPoint: prevMetadata.curriculumPoint.concat(
                  event.data.log.curriculumPoint
                ),
                curriculumLineString: prevMetadata.curriculumLineString.concat(
                  event.data.log.curriculumLineString
                ),
              } as Metadata
            }
          })
        }

        if (event.data.finished) {
          updateMetadataLoadingFinished(true)
        }
      }
    }

    return () => {
      const worker = workerRef.current
      if (worker) {
        worker.terminate()
      }
      workerRef.current = undefined
    }
  }, [url, workerRef, begin, session, end, context])

  const updateOffset = useCallback(
    (event: React.MouseEvent) => {
      const id = event.currentTarget.id
      if (id) {
        const offset = parseInt(id, 10) - metadata.gnss[0].time_stamp.seconds
        update({
          offset,
        })
      }
    },
    [metadata]
  )

  useEffect(() => {
    if (metadata && seconds && seconds.length > 0 && loadingFinished) {
      const time =
        metadata.vehicleState?.[0]?.time_stamp.seconds +
        offset -
        begin +
        context
      const index = metadata.vehicleState.findIndex(
        (vS) => vS.time_stamp.seconds === (time | 0)
      )
      if (index !== -1) {
        listRef.current?.scrollToItem(index)
      }
    }
  }, [offset, seconds, metadata, listRef, loadingFinished, begin, context])

  useEffect(() => {
    if (metadata) {
      updateMetadata(metadata)
      const seconds = metadata.gnss?.map((g) => g.time_stamp.seconds)
      updateSeconds(seconds)
    }
  }, [metadata])

  return (
    <div className={styles.metadataLog}>
      {metadata && (
        <List
          useIsScrolling={true}
          innerElementType="div"
          itemCount={metadata.vehicleState?.length}
          itemSize={size}
          height={ITEM_HEIGHT}
          width={'auto'}
          ref={listRef}
        >
          {({ index, style }) => {
            const vehicleMotion = findData(
              metadata.vehicleMotion,
              metadata.vehicleState[index].time_stamp
            )
            const gnss = findData(
              metadata.gnss,
              metadata.vehicleState[index].time_stamp
            )
            return (
              <div
                style={style}
                onClick={updateOffset}
                id={metadata.vehicleState[index].time_stamp.seconds.toString()}
              >
                <div ref={rowRef}>
                  {new Date(
                    metadata.vehicleState[index].time_stamp.seconds * 1000
                  ).toISOString()}
                  {vehicleMotion && (
                    <div>
                      {settings.acceleration.display && (
                        <div>
                          acceleration: {calculateAcceleration(vehicleMotion)}{' '}
                          m/s²
                        </div>
                      )}
                      {settings.brake_pedal_normalized.display && (
                        <div>
                          brake_pedal_normalized:{' '}
                          {vehicleMotion.brake_pedal_normalized}
                        </div>
                      )}
                      {settings.gas_pedal_normalized.display && (
                        <div>
                          gas_pedal_normalized:{' '}
                          {vehicleMotion.gas_pedal_normalized}
                        </div>
                      )}
                      {settings.gear.display && (
                        <div>gear: {vehicleMotion.gear}</div>
                      )}
                      {gnss && (
                        <div>
                          {settings.heading.display && (
                            <div>heading: {gnss.heading} deg</div>
                          )}
                          {settings.heading_error.display && (
                            <div>heading_error: {gnss.heading_error} deg</div>
                          )}
                          {settings.hp_loc_altitude.display && (
                            <div>hp_loc_altitude: {gnss.hp_loc_altitude}</div>
                          )}
                          {settings.hp_loc_latitude.display && (
                            <div>hp_loc_latitude: {gnss.hp_loc_latitude}</div>
                          )}
                          {settings.hp_loc_longitude.display && (
                            <div>hp_loc_longitude: {gnss.hp_loc_longitude}</div>
                          )}
                          {settings.position_covariance.display && (
                            <div>
                              position_covariance: [
                              {convertPositionCovariance(
                                gnss.position_covariance
                              ).join(', ')}
                              ] cm2
                            </div>
                          )}
                        </div>
                      )}
                      {settings.speed.display && (
                        <div>speed: {vehicleMotion.speed || 0} km/h</div>
                      )}
                      {settings.steering_angle.display && (
                        <div>
                          steering_angle: {vehicleMotion.steering_angle} deg
                        </div>
                      )}
                      {settings.steering_angle_normalized.display && (
                        <div>
                          steering_angle_normalized:{' '}
                          {vehicleMotion.steering_angle_normalized}
                        </div>
                      )}
                      {metadata.vehicleState &&
                        settings.turn_signal.display && (
                          <div>
                            turn_signal:{' '}
                            {
                              TurnSignal[
                                metadata.vehicleState[index].turn_signal
                              ]
                            }
                          </div>
                        )}
                    </div>
                  )}
                  {gnss && (
                    <div>
                      {settings.hp_loc_latitude.display && (
                        <div>hp_loc_latitude: {gnss.hp_loc_latitude}</div>
                      )}
                      {settings.hp_loc_longitude.display && (
                        <div>hp_loc_longitude: {gnss.hp_loc_longitude}</div>
                      )}
                      {settings.hp_loc_altitude.display && (
                        <div>hp_loc_altitude: {gnss.hp_loc_altitude}</div>
                      )}
                      {settings.heading.display && (
                        <div>heading: {gnss.heading} deg</div>
                      )}
                      {settings.heading_error.display && (
                        <div>heading_error: {gnss.heading_error} deg</div>
                      )}
                      {settings.position_covariance.display && (
                        <div>
                          position_covariance: [
                          {convertPositionCovariance(
                            gnss.position_covariance
                          ).join(', ')}
                          ] cm2
                        </div>
                      )}
                    </div>
                  )}
                  <hr />
                </div>
              </div>
            )
          }}
        </List>
      )}
    </div>
  )
}

export default MetadataLog
