// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__light--iCyId {\n  --sys-color-primary: #f9fafa;\n  --sys-color-secondary: #00a751;\n  --sys-color-background: #f9fafa;\n}\n.style__dark--HldDA {\n  --sys-color-primary: #313235;\n  --sys-color-secondary: #3892f3;\n  --sys-color-background: #313235;\n}\n.style__app--N5ild {\n  display: flex;\n  height: 100%;\n  width: 100%;\n  margin: 0;\n}\n.style__main--t22oY {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  background: #f9fafa;\n}\n", "",{"version":3,"sources":["webpack://./../components/src/theme.less","webpack://./src/style.less"],"names":[],"mappings":"AAEA;EACE,4BAAA;EACA,8BAAA;EAEA,+BAAA;ACFF;ADKA;EACE,4BAAA;EACA,8BAAA;EAEA,+BAAA;ACJF;AALA;EAEI,aAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAMJ;AAXA;EASI,WAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;AAKJ","sourcesContent":["@import './colors';\n\n.light {\n  --sys-color-primary: @color-asphalt-grey-099;\n  --sys-color-secondary: @color-green-060;\n\n  --sys-color-background: @color-asphalt-grey-099;\n}\n\n.dark {\n  --sys-color-primary: @color-asphalt-grey-020;\n  --sys-color-secondary: @color-blue-060;\n\n  --sys-color-background: @color-asphalt-grey-020;\n}\n","@import '@yaak/components/src/colors';\n@import '@yaak/components/src/spacing';\n@import '@yaak/components/src/theme';\n\n:local {\n  .app {\n    display: flex;\n    height: 100%;\n    width: 100%;\n    margin: 0;\n  }\n\n  .main {\n    width: 100%;\n    height: 100%;\n    margin: 0;\n    background: @color-asphalt-grey-099;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": "style__light--iCyId",
	"dark": "style__dark--HldDA",
	"app": "style__app--N5ild",
	"main": "style__main--t22oY"
};
export default ___CSS_LOADER_EXPORT___;
