import React, { useState } from 'react'
import Typography, {
  TypographySizes,
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import { Version } from '@yaak/components/src/types'
import style from './style.less'
import Switch from '@yaak/components/src/Switch'
import {
  ChartSettings as CSettings,
  MetadataKeys,
  useMetadataStore,
} from '../../stores/MetadataStore'
import { useShallow } from 'zustand/react/shallow'

interface DataSetLineProps {
  label: MetadataKeys
  color: string
}

const DataSetLine = ({ label, color }: DataSetLineProps) => {
  const { chartSettings, updateChartSettings } = useMetadataStore(
    useShallow((state) => ({
      chartSettings: state.chartSettings,
      updateChartSettings: state.updateChartSettings,
    }))
  )

  const [checked, setChecked] = useState(
    chartSettings[label as keyof CSettings].display
  )
  return (
    <>
      <div className={style.row}>
        <div className={style.row}>
          <span className={style.dot} style={{ background: color }} />
          <Typography
            className={style.label}
            type={TypographyTypes.label}
            version={Version.v2}
          >
            {label}
          </Typography>
        </div>
        <Switch
          checked={checked}
          onChange={() => {
            setChecked(!checked)
            updateChartSettings({
              ...chartSettings,
              [label]: {
                ...chartSettings[label as keyof CSettings],
                display: !checked,
              },
            })
          }}
        />
      </div>
    </>
  )
}

const ChartSettings = () => (
  <div className={style.settings}>
    <div className={style.row}>
      <Typography
        className={style.label}
        type={TypographyTypes.label}
        size={TypographySizes.small}
        version={Version.v2}
      >
        PLOT
      </Typography>
    </div>
    <DataSetLine label={'acceleration'} color={LESS_COLORS['color-pink-074']} />
    <DataSetLine
      label={'brake_pedal_normalized'}
      color={LESS_COLORS['color-lime-036']}
    />
    <DataSetLine
      label={'gas_pedal_normalized'}
      color={LESS_COLORS['color-lime-062']}
    />
    <DataSetLine label={'speed'} color={LESS_COLORS['new-color-yellow-059']} />
    <DataSetLine
      label={'steering_angle'}
      color={LESS_COLORS['new-color-purple-069']}
    />
    <DataSetLine
      label={'steering_angle_normalized'}
      color={LESS_COLORS['new-color-purple-039']}
    />
  </div>
)

export default ChartSettings
