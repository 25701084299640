import React, { useEffect, useState } from 'react'
import Typography, {
  TypographyTypes,
} from '@yaak/components/src/Typography/Typography'
import style from './style.less'
import { useVideosStore } from '../../stores/VideosStore'
import { Camera } from '@yaak/components/services/api/api'
import { findCamera } from '../../utils'
import TitleSelect from '../../components/TitleSelect'

const VideoSettings = () => {
  const { cameras, selectedCamera } = useVideosStore()
  const [camera, setCamera] = useState<Camera>()

  useEffect(() => {
    setCamera(findCamera(cameras, selectedCamera))
  }, [cameras, selectedCamera])

  return (
    <div className={style.settings}>
      <div className={style.row}>
        <Typography className={style.label} type={TypographyTypes.label}>
          Title
        </Typography>
        <input
          className={style.inputField}
          id={'titleInput'}
          value={camera?.name}
          disabled
        />
      </div>
      <div className={style.row}>
        <Typography type={TypographyTypes.title}>General</Typography>
      </div>
      <div className={style.row}>
        <Typography className={style.label} type={TypographyTypes.label}>
          Topic
        </Typography>
        {camera && <TitleSelect camera={camera} pre={'/topic/'} />}
      </div>
    </div>
  )
}

export default VideoSettings
