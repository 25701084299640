// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__settingsIcon--qdKaD, .style__resetIcon--h8L6n {\n  color: #62656a;\n  cursor: pointer;\n  margin-top: 3px;\n  margin-left: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/controls/style.less"],"names":[],"mappings":"AAEA;EAGI,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AAFJ","sourcesContent":["@import '@yaak/components/src/colors';\n\n:local {\n  .settingsIcon,\n  .resetIcon {\n    color: @color-asphalt-grey-040;\n    cursor: pointer;\n    margin-top: 3px;\n    margin-left: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsIcon": "style__settingsIcon--qdKaD",
	"resetIcon": "style__resetIcon--h8L6n"
};
export default ___CSS_LOADER_EXPORT___;
