import { useEffect } from 'react'

export function useClickOutside(
  ref: any,
  callback: (dependencies?: any) => void,
  dependencies?: any,
  anotherRef?: any
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // we pass another reference to mui popover to prevent closing on click
      const paperPopover =
        anotherRef?.current?.querySelector('div:nth-child(3)') ||
        anotherRef?.querySelector?.('div:nth-child(3)') ||
        document.querySelectorAll('[data-testid="picker-popup"]')[0]
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !paperPopover?.contains(event.target)
      ) {
        callback(dependencies)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, dependencies])
}
