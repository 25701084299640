import { Mosaic, MosaicWindow } from 'react-mosaic-component'
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import style from './style.less'
import classNames from 'classnames'
import Playback from '@yaak/components/src/Playback'
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer'
import Map from '../../components/Map'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getSessionInfo } from '@yaak/components/services/api/api'
import { useMosaicStore } from '../../stores/MosaicStore'
import { useVideosStore } from '../../stores/VideosStore'
import {
  ToastContext,
  ToastContextType,
} from '@yaak/components/context/toastContext'
import { usePlayerStore } from '../../stores/PlayerStore'
import { useMetadataStore } from '../../stores/MetadataStore'
import Breadcrumbs from '@yaak/components/src/Breadcrumbs'
import MetadataLog from '../../components/MetadataLog'
import VehicleMotionChart from '../../components/VehicleMotionChart'
import { ToastTypes } from '@yaak/components/src/Toast/Toast'
import AddPanelSelect from '../../components/AddPanelSelect'
import { useShallow } from 'zustand/react/shallow'
import Settings from '../Settings'
import controls from './controls'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'

export type ViewId = 'log' | 'videos' | 'settings' | 'map' | 'new'

interface DashboardProps {
  token: string
}

interface PlaybarProps {}

const Playbar: React.FunctionComponent<PlaybarProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { update, playing } = usePlayerStore(
    useShallow((state) => ({
      update: state.update,
      reset: state.reset,
      playing: state.playing,
    }))
  )

  const onJumpTo = useCallback(
    (jump: number) => {
      update({
        jump,
      })
      searchParams.set('offset', jump.toString())
      setSearchParams(searchParams, { replace: true })
    },
    [update]
  )

  return <Playback playing={playing} update={update} onJumpTo={onJumpTo} />
}

const Dashboard: React.FunctionComponent<DashboardProps> = ({ token }) => {
  const [searchParams] = useSearchParams()
  const begin = searchParams.get('begin')
  const end = searchParams.get('end')
  const context = searchParams.get('context')
  const { setShowToast } = useContext(ToastContext) as ToastContextType
  const [panelId, setPanelId] = useState<string>()
  const { sessionId } = useParams()
  const { config, update } = useMosaicStore(
    useShallow((state) => ({
      config: state.config,
      update: state.update,
    }))
  )
  const { updateUrl, reset, chart } = useMetadataStore(
    useShallow((state) => ({
      updateUrl: state.updateUrl,
      reset: state.reset,
      chart: state.chart,
    }))
  )

  const { updateCameras, updateCameraPositions, updateSession, session } =
    useVideosStore(
      useShallow((state) => ({
        updateCameras: state.updateCameras,
        updateCameraPositions: state.updateCameraPositions,
        updateSession: state.updateSession,
        session: state.session,
      }))
    )

  const {
    reset: resetPlayerStore,
    setContext,
    setBegin,
    setEnd,
    setOffset,
  } = usePlayerStore(
    useShallow((state) => ({
      reset: state.reset,
      setBegin: state.setBegin,
      setEnd: state.setEnd,
      setContext: state.setContext,
      setOffset: state.setOffset,
    }))
  )

  const mapRef = useRef<any>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const getVideos = async () => {
      if (sessionId) {
        const sessionInfo = await getSessionInfo({
          token,
          sessionId,
          onAlert: setShowToast,
        })
        updateCameras(sessionInfo?.cameras)
        if (context && begin && end) {
          const offsetURLStartTimestamp = new Date(
            (new Date(sessionInfo.startTimestamp).getTime() / 1000 +
              parseInt(begin) -
              parseInt(context)) *
              1000
          ).toISOString()
          const offsetURLEndTimestamp = new Date(
            new Date(sessionInfo.startTimestamp).getTime() +
              parseInt(end) * 1000 +
              parseInt(context) * 1000
          ).toISOString()
          updateSession({
            name: sessionInfo.canonicalName,
            startTimestamp: sessionInfo.startTimestamp,
            endTimestamp: sessionInfo.endTimestamp,
            offsetURLStartTimestamp,
            offsetURLEndTimestamp,
          })
        } else {
          updateSession({
            name: sessionInfo.canonicalName,
            startTimestamp: sessionInfo.startTimestamp,
            endTimestamp: sessionInfo.endTimestamp,
          })
        }
        updateCameraPositions(sessionInfo.cameras.map((camera) => camera.name))

        const url = sessionInfo.metadataMCAP

        if (url) {
          updateUrl(url)
        } else {
          setShowToast({
            text: `No metadata url available`,
            type: ToastTypes.error,
          })
        }
      }
    }
    token && getVideos()
  }, [token, sessionId, begin, context])

  useEffect(() => {
    return () => {
      reset()
      resetPlayerStore()
    }
  }, [reset])

  useEffect(() => {
    if (begin) {
      setBegin(parseInt(begin))
    }
    if (end) {
      setEnd(parseInt(end))
    }
  }, [searchParams, begin, end])

  useEffect(() => {
    if (context) {
      setContext(parseInt(context))
    }
  }, [searchParams, context])

  useEffect(() => {
    const offsetURL = searchParams.get('offset')
    if (offsetURL) {
      setOffset(parseFloat(offsetURL))
    }
  }, [searchParams])

  return (
    <div className={style.dashboard}>
      <div className={style.breadcrumbs}>
        <Breadcrumbs
          first={{
            text: 'Dataset',
          }}
          second={{
            text: session.name || '',
          }}
          version={Version.v2}
          onClick={() => {
            console.log('click')
            navigate(-1)
          }}
        />
        <AddPanelSelect />
      </div>
      <Mosaic<string>
        initialValue={config}
        onChange={(newLayout: any) => {
          if (newLayout?.first === 'panel-settings') {
            update({
              ...(newLayout?.second as Object),
            })
          } else {
            update(newLayout)
          }
        }}
        renderTile={(id, path) => {
          return id === 'panel-videos' ? (
            <div className={style.videos}>
              <VideoPlayer token={token} />
            </div>
          ) : id === 'panel-map' ? (
            <MosaicWindow<ViewId>
              path={path}
              title={
                (
                  <Typography type={TypographyTypes.label} version={Version.v2}>
                    MAP
                  </Typography>
                ) as any
              }
              key={id}
              toolbarControls={controls.mapPanel({
                id,
                update,
                config,
                setPanelId,
                ref: mapRef,
              })}
            >
              <Map token={token} />
            </MosaicWindow>
          ) : id === 'panel-metadata' ? (
            <MosaicWindow<ViewId>
              path={path}
              title={
                (
                  <Typography type={TypographyTypes.label} version={Version.v2}>
                    METADATA
                  </Typography>
                ) as any
              }
              key={id}
              toolbarControls={controls.metadataPanel({
                id,
                update,
                config,
                setPanelId,
              })}
            >
              <MetadataLog />
            </MosaicWindow>
          ) : id === 'panel-plot' ? (
            <MosaicWindow<ViewId>
              path={path}
              title={
                (
                  <Typography type={TypographyTypes.label} version={Version.v2}>
                    PLOT
                  </Typography>
                ) as any
              }
              key={id}
              toolbarControls={controls.chartPanel({
                id,
                update,
                config,
                setPanelId,
                ref: chart,
              })}
            >
              <div className={style.chartWrapper}>
                <VehicleMotionChart />
              </div>
            </MosaicWindow>
          ) : id === 'panel-settings' ? (
            <Settings panelId={panelId} />
          ) : (
            <></>
          )
        }}
        blueprintNamespace={'bp4'}
        className={classNames('mosaic-blueprint-theme')}
      />
      {sessionId && <Playbar />}
    </div>
  )
}

export default Dashboard
