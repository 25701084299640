import React from 'react'
import style from './style.less'

const Loader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={style.loadingBar}
    >
      <path
        d="M14 2C16.3734 2 18.6935 2.70379 20.6668 4.02236C22.6402 5.34094 24.1783 7.21508 25.0866 9.4078C25.9948 11.6005 26.2324 14.0133 25.7694 16.3411C25.3064 18.6689 24.1635 20.807 22.4853 22.4853C20.8071 24.1635 18.6689 25.3064 16.3411 25.7694C14.0133 26.2324 11.6005 25.9948 9.4078 25.0866C7.21509 24.1783 5.34094 22.6402 4.02236 20.6668C2.70379 18.6935 2 16.3734 2 14"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Loader
