import React, { ReactElement } from 'react'
import DrivesOverview from '@yaak/components/src/DrivesOverview'
import { useNavigate } from 'react-router-dom'
import style from './style.less'
import { Version } from '@yaak/components/src/types'

interface DrivesPageProps {
  token: string
}

const DrivesPage: React.FunctionComponent<DrivesPageProps> = ({
  token,
}): ReactElement => {
  const navigate = useNavigate()
  return (
    <div className={style.drivesOverview}>
      <DrivesOverview
        token={token}
        version={Version.v2}
        nutron={true}
        fixedColumns={2}
        onClick={(sId: string) => {
          navigate(`/dataset/session-logs/${sId}`)
        }}
        withHeader={false}
        searchQueryEnabled={true}
        filter={{ type: 'REGULAR' }}
      />
    </div>
  )
}

export default DrivesPage
