import React, { ForwardedRef, forwardRef, memo } from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '../Typography/Typography'
import classNames from 'classnames'

export const BadgeType = {
  yellow: 'yellow',
  grey: 'grey',
  green: 'green',
  orange: 'orange',
  red: 'red',
  blue: 'blue',
  purple: 'purple',
}

export interface BadgeProps {
  label: string | React.ReactNode
  type?: string
  icon?: string | React.ReactNode
  withDot?: boolean
  svg?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(function Badge(
  {
    label,
    type = BadgeType.green,
    withDot = false,
    svg = false,
    icon,
    onClick,
  },
  ref?: ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      ref={ref}
      aria-label="badge"
      onClick={onClick}
      className={classNames(
        style.badge,
        svg ? style.svg : undefined,
        type === BadgeType.green ? style.badgeGreen : undefined,
        type === BadgeType.grey ? style.badgeGrey : undefined,
        type === BadgeType.red ? style.badgeRed : undefined,
        type === BadgeType.yellow ? style.badgeYellow : undefined,
        type === BadgeType.blue ? style.badgeBlue : undefined,
        type === BadgeType.orange ? style.badgeOrange : undefined,
        type === BadgeType.purple ? style.badgePurple : undefined
      )}
    >
      {withDot && <span className={style.dot}></span>}
      {typeof icon === 'string' ? <img src={icon} alt={'icon'} /> : icon}
      <Typography type={TypographyTypes.label}>{label}</Typography>
    </div>
  )
})

export default memo(Badge)
