import React, { memo, useCallback, useEffect, useState } from 'react'
import style from './style.less'
import { useSearchParams } from 'react-router-dom'
import Typography, { TypographyTypes } from '../Typography'
import { MergedListKey } from '../SearchQueryBar/SearchQueryBar'
import { getSearchQueryParams } from './utils'

export interface FilterMenuMultiTagProps {
  tag: Record<MergedListKey, string | boolean>
  multi: string[]
}

const FilterMenuMultiTag = ({ tag, multi }: FilterMenuMultiTagProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [checked, setChecked] = useState<Record<string, boolean>>({})

  useEffect(() => {
    const q = searchParams.get('q')
    if (q) {
      const params = decodeURIComponent(q)
      const values = params
        ?.split('&')
        .filter((value) => (tag.key as string)?.includes(value))[0]
        ?.split(' ')
      values?.[2]?.split(',')?.forEach((value) => {
        setChecked((prev) => {
          return {
            ...prev,
            [value]: true,
          }
        })
      })
    }
  }, [searchParams, tag])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.getAttribute('data-key')
      const result = getSearchQueryParams({
        searchParams,
        key: tag.key as string,
      })
      if (result) {
        const { params, queryParamToEdit, queryParamRest } = result
        const checked = event.target.checked
        if (queryParamToEdit) {
          const ifAlreadyExists = queryParamToEdit.length > 2
          if (checked) {
            const newTag = ifAlreadyExists ? `,${value}` : ` ${value}`
            const newFullTag = queryParamToEdit.join(' ') + newTag
            searchParams.set(
              'q',
              queryParamRest
                ? params
                    .map((param) =>
                      param.replace(/ /g, '') === queryParamToEdit.join('')
                        ? newFullTag
                        : param
                    )
                    .join('&')
                : newFullTag
            )
          } else {
            const tagValue = queryParamToEdit[2].split(',')
            const updatedTagValue = tagValue
              .filter((v) => v !== value)
              .join(',')
            const newFullTag = `${queryParamToEdit[0]} ${queryParamToEdit[1]} ${updatedTagValue}`
            searchParams.set(
              'q',
              queryParamRest
                ? params
                    .map((param) =>
                      param.replace(/ /g, '') === queryParamToEdit.join('')
                        ? newFullTag
                        : param
                    )
                    .join('&')
                : newFullTag
            )
          }
          setSearchParams(searchParams, { replace: true })
        }
      }
    },
    [searchParams, tag]
  )

  return (
    <div className={style.filterMenuMultiTags}>
      {multi?.map((value, i) => {
        return (
          <div key={`${value}_${i}`} className={style.tagMultiValue}>
            <input
              type="checkbox"
              onChange={onChange}
              data-key={value}
              checked={checked[value]}
            />
            <Typography type={TypographyTypes.label}>{value}</Typography>
          </div>
        )
      })}
    </div>
  )
}

export default memo(FilterMenuMultiTag)
