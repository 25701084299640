import { create } from 'zustand'
import { MosaicParent } from 'react-mosaic-component/src/types'

export const PANEL_ID_VIDEO = 'VIDEO'

export type CAMERA_POSITIONS =
  | 'CAM_REAR'
  | 'CAM_FRONT_CENTER'
  | 'CAM_LEFT_BACKWARD'
  | 'CAM_RIGHT_BACKWARD'
  | 'CAM_RIGHT_FORWARD'
  | 'CAM_LEFT_FORWARD'
  | 'panel-settings'

export const CAMERA_POSITIONS_MAP: Record<string, number> = {
  CAM_REAR: 0,
  CAM_FRONT_CENTER: 1,
  CAM_LEFT_BACKWARD: 2,
  CAM_RIGHT_BACKWARD: 3,
  CAM_RIGHT_FORWARD: 4,
  CAM_LEFT_FORWARD: 5,
}

export const VIDEO_PLAYER_CONFIG: MosaicParent<CAMERA_POSITIONS> = {
  direction: 'row',
  first: 'CAM_LEFT_BACKWARD',
  second: {
    direction: 'row',
    first: 'CAM_FRONT_CENTER',
    second: 'CAM_RIGHT_BACKWARD',
  },
  splitPercentage: 33,
}

interface VideoPlayerStoreState {
  config: MosaicParent<CAMERA_POSITIONS>
  update: (state: any) => void
}

export const useVideoPlayerStore = create<VideoPlayerStoreState>((set) => ({
  config: VIDEO_PLAYER_CONFIG,
  update: (config) =>
    set(() => ({
      config,
    })),
}))
