import {
  FILTER_VALUES_MATCHING_INPUT_KEYS,
  FILTER_VALUES_MATCHING_MULTI_KEYS,
  FilterValuesMatchingInputProps,
} from './consts'

export const findMatchingValues = (
  obj:
    | Record<FILTER_VALUES_MATCHING_MULTI_KEYS, string[]>
    | Record<FILTER_VALUES_MATCHING_INPUT_KEYS, FilterValuesMatchingInputProps>,
  value: string
) => Object.keys(obj).filter((key) => value.includes(key))[0]

interface GetSearchQueryParamsProps {
  searchParams: URLSearchParams
  key: string
}

export const getSearchQueryParams = ({
  searchParams,
  key,
}: GetSearchQueryParamsProps):
  | {
      params: string[]
      queryParamToEdit: string[] | undefined
      queryParamRest: string | undefined
    }
  | undefined => {
  const query = searchParams.get('q')
  if (query) {
    const q = decodeURIComponent(query)
    const params = q?.split('&')
    const queryParamToEdit = params
      .find((value) => key?.includes(value))
      ?.split(' ')
      .filter((item) => item !== '')
    const queryParamRest = params.find((value) => !key?.includes(value))
    return {
      params,
      queryParamToEdit,
      queryParamRest,
    }
  }
}
