// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__dashboard--tugas {\n  height: calc(100% - 8.1rem);\n  width: 100%;\n  margin-top: 3rem;\n  padding-bottom: 4rem;\n}\n.style__videos--He6xf {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n  margin: 0;\n}\n.style__breadcrumbs--ABNr6 {\n  display: flex;\n  justify-content: space-between;\n  margin-top: -40px;\n  padding-left: 1rem;\n  padding-bottom: 1rem;\n  margin-right: 1rem;\n}\n.style__chartWrapper--nKnY2 {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n}\n.style__settingsIcon--GFbb4, .style__resetIcon--W7zqK {\n  color: #62656a;\n  cursor: pointer;\n  margin-top: 3px;\n  margin-left: 6px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/style.less"],"names":[],"mappings":"AAEA;EAEI,2BAAA;EACA,WAAA;EACA,gBAAA;EACA,oBAAA;AAFJ;AAHA;EASI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;AAHJ;AAXA;EAkBI,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,kBAAA;EACA,oBAAA;EACA,kBAAA;AAJJ;AAnBA;EA2BI,aAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AALJ;AAzBA;EAmCI,cAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;AANJ","sourcesContent":["@import '@yaak/components/src/colors';\n\n:local {\n  .dashboard {\n    height: calc(100% - 8.1rem);\n    width: 100%;\n    margin-top: 3rem;\n    padding-bottom: 4rem;\n  }\n\n  .videos {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n    width: 100%;\n    margin: 0;\n  }\n\n  .breadcrumbs {\n    display: flex;\n    justify-content: space-between;\n    margin-top: -40px;\n    padding-left: 1rem;\n    padding-bottom: 1rem;\n    margin-right: 1rem;\n  }\n\n  .chartWrapper {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n  }\n\n  .settingsIcon,\n  .resetIcon {\n    color: @color-asphalt-grey-040;\n    cursor: pointer;\n    margin-top: 3px;\n    margin-left: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard": "style__dashboard--tugas",
	"videos": "style__videos--He6xf",
	"breadcrumbs": "style__breadcrumbs--ABNr6",
	"chartWrapper": "style__chartWrapper--nKnY2",
	"settingsIcon": "style__settingsIcon--GFbb4",
	"resetIcon": "style__resetIcon--W7zqK"
};
export default ___CSS_LOADER_EXPORT___;
