import React from 'react'
import style from './style.less'
import Typography, { TypographyTypes } from '@yaak/components/src/Typography'
import { Version } from '@yaak/components/src/types'
import yaakLogo from '@yaak/components/assets/images/yaakLogo2.0.svg'
import { useLocation, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname
  return (
    <div className={style.header}>
      <img src={yaakLogo} alt="Logo" className={style.logo} />
      <Typography
        className={
          !path.startsWith('/dataset/') ? style.headerOpacity : undefined
        }
        type={TypographyTypes.title}
        version={Version.v2}
        onClick={() => navigate(-1)}
      >
        {'Dataset'}
      </Typography>
      <Typography
        className={
          !path.startsWith('/metrics') ? style.headerOpacity : undefined
        }
        type={TypographyTypes.title}
        version={Version.v2}
        onClick={() => navigate('/metrics')}
      >
        {'Metrics'}
      </Typography>
    </div>
  )
}

export default Header
