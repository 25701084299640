export const getPixelForXValue = (
  scale: any,
  xValue: number | undefined
): number | undefined => {
  if (xValue === undefined) {
    return undefined
  }

  const pixelRange = scale.right - scale.left
  if (pixelRange <= 0) {
    return undefined
  }

  if (xValue < scale.min || xValue > scale.max) {
    return undefined
  }

  return (
    scale.left + ((xValue - scale.min) / (scale.max - scale.min)) * pixelRange
  )
}

export const getXValueAtPixel = (scale: any, pixelX: number): number => {
  if (!scale) {
    return -1
  }

  const pixelRange = scale.right - scale.left
  if (pixelRange <= 0) {
    return -1
  }

  return (
    scale.min + ((pixelX - scale.left) / pixelRange) * (scale.max - scale.min)
  )
}
