import React from 'react'
import Typography, { TypographyTypes } from '../Typography'
import classNames from 'classnames'
import style from './style.less'
import { Version } from '../types'

interface GridCellTextProps {
  column: any
}

const GridCellText = ({ column }: GridCellTextProps) => {
  return (
    <Typography
      type={TypographyTypes.body}
      className={classNames(
        column.hidden ? style.hidden : undefined,
        column.ellipsis ? style.ellipsis : undefined,
        column.tooltip && !column.icon ? style.ellipsisTooltip : undefined,
        column.version === Version.v2 ? style.colorV2 : undefined,
        column.click ? style.click : undefined
      )}
      version={column.version === Version.v1 ? Version.v1 : Version.v2}
      onClick={column.click}
    >
      {column.text || column}
    </Typography>
  )
}

export default GridCellText
