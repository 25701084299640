export const TOP_LEVEL_FILTER_ITEMS = [
  'Environment',
  'Route infrastructure',
  'Route topography',
  'Session info',
  'Telemetry',
  'Triage',
]

export const TOP_LEVEL_FILTER_ITEMS_ICONS: Record<string, string> = {
  Environment: 'Earth',
  'Route infrastructure': 'Traffic',
  'Route topography': 'Elevation',
  'Session info': 'Information',
  Telemetry: 'Speedometer',
  Triage: 'Annotation',
}

export const SECOND_LEVEL_FILTER_ITEMS: Record<string, string[]> = {
  Environment: ['Lighting', 'Weather'],
  'Route infrastructure': [
    'Lane count',
    'Max speed',
    'Route attributes',
    'Route facilities',
    'Route junctions',
  ],
  'Route topography': ['Route surface', 'Route type'],
  'Session info': [
    'Canonical ID',
    'Bitrate',
    'Date',
    'Driver ID',
    'Hardware ID',
    // 'Location',
    'Organisation',
    'Policy',
    'Session ID',
  ],
  Telemetry: [
    'Acceleration',
    'Brake pedal',
    'Gas pedal',
    'Gear',
    'Speed',
    'Steering angle',
    'Turn signal',
  ],
  Triage: [
    'Annotation',
    'Score',
    'Score, breaking',
    'Score, acceleration',
    'Score, steering angle',
  ],
}

export type FILTER_VALUES_MATCHING_MULTI_KEYS =
  | 'lighting'
  | 'weather'
  | 'policy'
  | 'annotation'
  | 'turn-signal'
  | 'route-facilities'
  | 'route-type'
  | 'route-surface'
  | 'gear'
  | 'route-attributes'
  | 'route-junctions'

export type FILTER_VALUES_MATCHING_INPUT_KEYS =
  | 'driver-ID'
  | 'bitrate'
  | 'canonical-ID'
  | 'hardware-ID'
  | 'location'
  | 'organisation'
  | 'session-ID'
  | 'score'
  | 'acceleration'
  | 'score_brake-pedal'
  | 'score_gas-pedal'
  | 'score_steering-angle'
  | 'brake-pedal'
  | 'gas-pedal'
  | 'speed'
  | 'steering-angle'
  | 'lane-count'

export interface FilterValuesMatchingInputProps {
  placeholder: string
}

export const FILTER_VALUES_MATCHING_INPUT: Record<
  FILTER_VALUES_MATCHING_INPUT_KEYS,
  FilterValuesMatchingInputProps
> = {
  'driver-ID': {
    placeholder: 'e.g. auth0|63614e488a9f30a0a278347c',
  },
  bitrate: {
    placeholder: 'e.g. 4, 6',
  },
  'canonical-ID': {
    placeholder: 'Add value',
  },
  'hardware-ID': {
    placeholder: 'Add value',
  },
  location: {
    placeholder: 'e.g. Berlin, 12099',
  },
  organisation: {
    placeholder: 'e.g. Yaak AI',
  },
  'session-ID': {
    placeholder: 'Add value',
  },
  score: {
    placeholder: 'e.g. 25, 25%, 0.25',
  },
  'score_brake-pedal': {
    placeholder: 'e.g. 25, 25%, 0.25',
  },
  'score_gas-pedal': {
    placeholder: 'e.g. 25, 25%, 0.25',
  },
  'score_steering-angle': {
    placeholder: 'e.g. 25, 25%, 0.25',
  },
  acceleration: {
    placeholder: 'e.g. 0.3, 5',
  },
  'brake-pedal': {
    placeholder: 'e.g. 0.5',
  },
  'gas-pedal': {
    placeholder: 'e.g. 0.5',
  },
  speed: {
    placeholder: 'e.g. 130',
  },
  'steering-angle': {
    placeholder: 'e.g. 180',
  },
  'lane-count': {
    placeholder: 'e.g. 4',
  },
}

export const FILTER_VALUES_MATCHING_MULTI: Record<
  FILTER_VALUES_MATCHING_MULTI_KEYS,
  string[]
> = {
  annotation: [
    'Distance',
    'Handling',
    'Instructor pedal',
    'Placement',
    'Right of way',
    'Signs & lights',
    'Speed',
    'Turn signal',
    'Visual check',
  ],
  gear: ['Breaking', 'Drive', 'Neutral', 'Park', 'Reverse'],
  lighting: ['Day', 'Night', 'Transition'],
  policy: ['Expert', 'Student'],
  'turn-signal': ['Left', 'Right', 'Off'],
  'route-attributes': [
    'GIVE_WAY',
    'HILL_DRIVE',
    'LIMITED_ACCESS_WAY',
    'LIVING_STREET',
    'LOW_SPEED_REGION',
    'ONE_WAY',
    'PRIORITY_FORWARD_BACKWARD',
    'PRIORITY_WAY',
    'RIGHT_OF_WAY',
    'TUNNEL',
  ],
  'route-facilities': [
    'BUS_STOP',
    'LOWERED_KERB',
    'PEDESTRIAN_CROSSING',
    'TRAFFIC_CALMER',
    'TRAIN_CROSSING',
    'TRAM_TRACKS',
  ],
  'route-junctions': [
    'ENTERING_MOVING_TRAFFIC',
    'JUNCTION_RIGHT_BEFORE_LEFT',
    'MERGE_IN_OUT_ON_HIGHWAY',
    'MULTILANE_LEFT',
    'MULTILANE_RIGHT',
    'PROTECTED_LEFT',
    'RIGHT_BEFORE_LEFT',
    'RIGHT_TURN_ON_RED',
    'ROAD_NARROWS',
    'ROUNDABOUT',
    'UNPROTECTED_LEFT',
    'UNPROTECTED_RIGHT_WITH_BIKE',
  ],
  'route-surface': [
    'acrylic',
    'artificial_turf',
    'asphalt',
    'bricks',
    'carpet',
    'chipseal',
    'clay',
    'cobblestone',
    'compacted',
    'concrete',
    'concrete:lanes',
    'concrete:plates',
    'dirt',
    'earth',
    'fine_gravel',
    'grass',
    'grass_paver',
    'gravel',
    'ground',
    'ice',
    'metal',
    'metal_grid',
    'mud',
    'paved',
    'paving_stones',
    'pebblestone',
    'plastic',
    'rock',
    'rubber',
    'salt',
    'sand',
    'sett',
    'shells',
    'snow',
    'stepping_stones',
    'tartan',
    'tiles',
    'unhewn_cobblestone',
    'unpaved',
    'wood',
    'woodchips',
  ],
  weather: [
    'Ash',
    'Clear',
    'Clouds',
    'Drizzle',
    'Dust',
    'Fog',
    'Haze',
    'Mist',
    'Rain',
    'Sand',
    'Smoke',
    'Snow',
    'Squall',
    'Thunderstorm',
    'Tornado',
  ],
  'route-type': [
    'Bridleway',
    'Bus Guideway',
    'Busway',
    'Corridor',
    'Crossing',
    'Cycleway',
    'Escape',
    'Footway',
    'Highway',
    'Living Street',
    'Motorway Link',
    'Path',
    'Pedestrian',
    'Primary Link',
    'Primary',
    'Raceway',
    'Residential',
    'Road',
    'Secondary',
    'Service',
    'Sidewalk',
    'Steps',
    'Tertiary Link',
    'Tertiary',
    'Track',
    'Traffic Island',
    'Trunk Link',
    'Trunk',
    'Unclassified',
    'Via Ferrata',
  ],
}

export const SEARCH_TAGS_MAPPING: Record<string, string> = {
  Lighting: 'lighting = ',
  Weather: 'weather = ',
  'Lane count': 'lane-count = ',
  'Max speed': 'max-speed = ',
  'Route attributes': 'route-attributes = ',
  'Route facilities': 'route-facilities = ',
  'Route junctions': 'route-junctions = ',
  'Route surface': 'route-surface = ',
  'Route type': 'route-type = ',
  'Canonical ID': 'canonical-ID = ',
  Bitrate: 'bitrate = ',
  Date: 'date = ',
  'Driver ID': 'driver-ID = ',
  'Hardware ID': 'hardware-ID = ',
  // Location: 'location = ',
  Organisation: 'organisation = ',
  Policy: 'policy = ',
  'Session ID': 'session-ID = ',
  Acceleration: 'acceleration = ',
  'Brake pedal': 'brake-pedal = ',
  'Gas pedal': 'gas-pedal = ',
  Gear: 'gear = ',
  Speed: 'speed = ',
  'Steering angle': 'steering-angle = ',
  'Turn signal': 'turn-signal = ',
  Annotation: 'annotation = ',
  Score: 'score = ',
  'Score, breaking': 'score_brake-pedal = ',
  'Score, acceleration': 'score_gas-pedal = ',
  'Score, steering angle': 'score_steering-angle = ',
}

const ALL_OPERATORS = ['=', '!=', '<', '<=', '>', '>=']
const EQUALS_OPERATORS = ['=', '!=']

export const OPERATORS_MAPPING: Record<string, string[]> = {
  bitrate: ALL_OPERATORS,
  policy: EQUALS_OPERATORS,
  'hardware-ID': EQUALS_OPERATORS,
  driver: EQUALS_OPERATORS,
  organisation: EQUALS_OPERATORS,
  'session-ID': EQUALS_OPERATORS,
  'canonical-ID': EQUALS_OPERATORS,
  location: EQUALS_OPERATORS,
  annotation: EQUALS_OPERATORS,
  score: ALL_OPERATORS,
  'score_brake-pedal': ALL_OPERATORS,
  'score_gas-pedal': ALL_OPERATORS,
  'score_steering-angle': ALL_OPERATORS,
  lighting: EQUALS_OPERATORS,
  weather: EQUALS_OPERATORS,
  'steering-angle': ALL_OPERATORS,
  'gas-pedal': ALL_OPERATORS,
  'brake-pedal': ALL_OPERATORS,
  gear: EQUALS_OPERATORS,
  speed: ALL_OPERATORS,
  acceleration: ALL_OPERATORS,
  turn_signal: EQUALS_OPERATORS,
  'route-surface': EQUALS_OPERATORS,
  'route-type': EQUALS_OPERATORS,
  'lane-count': ALL_OPERATORS,
  'max-speed': ALL_OPERATORS,
  'route-attributes': EQUALS_OPERATORS,
  'route-facilities': EQUALS_OPERATORS,
  'route-junctions': EQUALS_OPERATORS,
  date: ['='],
}

export const SEARCH_ALL_FILTERS_ID = 'search-all-filters'
