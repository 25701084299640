export const ROAD_TYPE: Record<number, string> = {
  0: 'Motorway',
  1: 'Trunk',
  2: 'Primary',
  3: 'Secondary',
  4: 'Tertiary',
  5: 'Residential',
  6: 'Motorway_link',
  7: 'Trunk_link',
  8: 'Primary_link',
  9: 'Secondary_link',
  10: 'Tertiary_link',
  11: 'Living_street',
  12: 'Service',
  13: 'Unclassified',
  14: 'Raceway',
  15: 'Track',
}

export const SURFACE: Record<number, string> = {
  0: 'Paved',
  1: 'Asphalt',
  2: 'Concrete',
  3: 'Sett',
  4: 'Cobblestone',
  5: 'Paving stones',
  6: 'Dirt',
  7: 'Grass',
  8: 'Ground',
  9: 'Unpaved',
  10: 'Unknown',
}
