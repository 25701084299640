import React from 'react'
import stylev1 from './style.less'
import styleV2 from './style-v2.less'
import classNames from 'classnames'
import { Version } from '../types'

export const TypographyTypes = {
  body: 'body',
  display: 'display',
  headline: 'headline',
  label: 'label',
  link: 'link',
  title: 'title',
}

export interface TypographySize {
  small: string
  large: string
}

export const TypographySizes = {
  small: 'small',
  large: 'large',
  // v2
  extraSmall: 'extraSmall',
}

export const TypographyStyles = {
  bold: 'bold',
  light: 'light',
  // v2
  medium: 'medium',
  emphasis: 'emphasis',
  mono: 'mono',
}

export interface TypographyProps {
  className?: string
  size?: string
  styles?: string
  children: React.ReactNode
  version?: Version
  type: string
  url?: string
  color?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Typography: React.FunctionComponent<TypographyProps> = ({
  size,
  type,
  children,
  className,
  styles,
  url,
  color,
  version = Version.v1,
  onClick,
}) => {
  const style = version === Version.v1 ? stylev1 : styleV2
  return (
    <div
      className={classNames(
        className,
        style[type],
        styles ? style[styles] : undefined,
        size === TypographySizes.large ? style[`${type}Large`] : undefined,
        size === TypographySizes.small ? style[`${type}Small`] : undefined,
        size === TypographySizes.extraSmall
          ? style[`${type}ExtraSmall`]
          : undefined,
        url ? style.underscore : undefined,
        onClick ? style.action : undefined
      )}
      style={{ color: LESS_COLORS[color || ''] }}
      onClick={onClick}
    >
      {url && !onClick ? <a href={url}>{children}</a> : children}
    </div>
  )
}

export default Typography
