import React, { ChangeEvent, memo, useCallback, useState } from 'react'
import style from './style.less'
import { useSearchParams } from 'react-router-dom'
import { FilterValuesMatchingInputProps } from './consts'
import Typography, { TypographyTypes } from '../Typography'
import { MergedListKey } from '../SearchQueryBar/SearchQueryBar'
import { getSearchQueryParams } from './utils'

export interface FilterMenuInputTagProps {
  tag: Record<MergedListKey, string | boolean>
  input: FilterValuesMatchingInputProps
}

const FilterMenuInputTag = ({ tag, input }: FilterMenuInputTagProps) => {
  const [inputValue, setInputValue] = useState('')
  const [newFullTag, setNewFullTag] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value
      const result = getSearchQueryParams({
        searchParams,
        key: tag.key as string,
      })
      if (result) {
        const { params, queryParamToEdit, queryParamRest } = result
        if (queryParamToEdit) {
          const ifAlreadyExists = queryParamToEdit.length > 2
          const newTag = [...queryParamToEdit]
          ifAlreadyExists ? (newTag[2] = inputValue) : newTag.push(inputValue)
          const newFullTag = newTag.join(' ')
          setInputValue(inputValue)
          setNewFullTag(
            queryParamRest
              ? params
                  .map((param) => {
                    return param.replace(/ /g, '') === queryParamToEdit.join('')
                      ? newFullTag
                      : param
                  })
                  .join('&')
              : newFullTag
          )
        }
      }
    },
    [searchParams, tag]
  )

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        searchParams.set('q', newFullTag)
        setSearchParams(searchParams)
      }
    },
    [searchParams, newFullTag]
  )

  return (
    <div className={style.filterMenuInputContainer}>
      <input
        className={style.filterMenuInput}
        value={inputValue}
        placeholder={input.placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <Typography type={TypographyTypes.label}>{tag.unit}</Typography>
    </div>
  )
}

export default memo(FilterMenuInputTag)
