import React, { memo, useEffect, useRef, useState } from 'react'
import style from './style.less'
import Badge from '../Badge'
import { BadgeType } from '../Badge/Badge'
import Icon from '../Icon/Icon'
import { Version } from '../types'
import FilterMenuTag from '../FilterMenu/FilterMenuTag'
import { useClickOutside } from '../../customHooks/useClickOutside'
import { getSearchQueryParams } from '../FilterMenu/utils'
import { useSearchParams } from 'react-router-dom'

export interface SearchQueryTagProps {
  tag: any
}

const SearchQueryTag = ({ tag }: SearchQueryTagProps) => {
  const tagRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(
    tag.value.split(' ').filter((v: string) => v !== '').length === 2
  )
  const [createdRef, setCreatedRef] = useState<HTMLDivElement | null>(null)
  const [operatorsRef, setOperatorsRef] = useState<HTMLDivElement | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    ref && setCreatedRef(ref as any)
  }, [ref])

  useClickOutside(
    tagRef,
    (props) => {
      const [, , value] = props.tag.value.split(' ')
      setOpen(false)
      if (!value) {
        const result = getSearchQueryParams({
          searchParams,
          key: tag.key as string,
        })
        if (result) {
          const { queryParamToEdit, queryParamRest } = result
          if (queryParamToEdit) {
            setSearchParams(queryParamRest)
          }
        }
      }
    },
    { tag, searchParams },
    operatorsRef
  )

  return (
    <div
      key={tag.key as string}
      className={style.tagContainer}
      ref={tagRef}
      onClick={() => {
        setOpen(true)
      }}
    >
      <Badge
        ref={ref}
        key={tag.key as string}
        type={BadgeType.grey}
        label={
          (tag.value as string).split(' ').filter((v) => v !== '').length > 2
            ? `${tag.value} ${tag.unit}`
            : tag.value
        }
        icon={
          <Icon
            name={'Number'}
            version={Version.v2}
            className={style.hashIcon}
          />
        }
      />
      <FilterMenuTag
        tag={tag}
        open={open}
        ref={createdRef as any}
        setOperatorsRef={setOperatorsRef}
      />
    </div>
  )
}

export default memo(SearchQueryTag)
