import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import style from './style.less'
import Icon, { IconSizes } from '../Icon/Icon'
import Typography, {
  TypographyTypes,
  TypographySizes,
  TypographyStyles,
} from '@yaak/components/src/Typography'
import {
  formatTimestampToDate,
  toDurationPrecise,
  toDurationTimeMs,
} from '@yaak/admin/src/helpers/time'
import { formatTime } from '@yaak/nutron/src/utils/time'
import Tooltip from '../Tooltip'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { usePlayerStore } from '@yaak/nutron/src/stores/PlayerStore'
import { useShallow } from 'zustand/react/shallow'
import { MarkerIcon } from '@yaak/components/assets'
import { useMetadataStore } from '@yaak/nutron/src/stores/MetadataStore'
import { SafetyScore } from '@yaak/nutron/src/utils/protobufParse'
import { useVideosStore } from '@yaak/nutron/src/stores/VideosStore'
import { SessionTimestamp } from '../../services/api/api'
import classNames from 'classnames'
import { Version } from '../types'
import { getSafetyScoreColor } from '@yaak/admin/src/helpers/score'
import { useSearchParams } from 'react-router-dom'

const availableRates = ['0.1x', '0.2x', '0.5x', '0.8x', '1x', '2x', '3x', '5x']

export interface PlaybackProps {
  playing: boolean
  update: (store: any) => void
  onJumpTo: (jump: number) => void
}

interface Options {
  duration: number
  elapsed: number | null
  markerPointPosition: number
  markerPosition: number
  markerVisible: boolean
  rate: string
  width: number
  mouseMove: boolean
}

const PADDING = 4

interface getScorePixelPositionsProps {
  ref: RefObject<HTMLDivElement>
  safetyScore: SafetyScore
  session: SessionTimestamp
  duration: number
}

const getScorePixelPositions = ({
  ref,
  safetyScore,
  session,
  duration,
}: getScorePixelPositionsProps) => {
  const result = {
    backgroundColor: '',
    width: 0,
    left: 0,
  }
  if (ref.current) {
    const bounds = ref.current.getBoundingClientRect()
    const second =
      safetyScore.clip.start_timestamp.seconds -
      new Date(
        session.offsetURLStartTimestamp || session.startTimestamp
      ).getTime() /
        1000
    result.left = (second * bounds.width) / (duration / 1000)
    result.width =
      (safetyScore.clip.end_timestamp.seconds * bounds.width) /
        (duration / 1000) -
      (safetyScore.clip.start_timestamp.seconds * bounds.width) /
        (duration / 1000)
    result.backgroundColor = getSafetyScoreColor(safetyScore.score)
  }
  return result
}

const Playback: React.FunctionComponent<PlaybackProps> = ({
  playing,
  update,
  onJumpTo,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { hoverSync, begin, context, end, offset } = usePlayerStore(
    useShallow((state) => ({
      offset: state.offset,
      end: state.end,
      begin: state.begin,
      context: state.context,
      hoverSync: state.hoverSync,
    }))
  )
  const { metadata } = useMetadataStore(
    useShallow((state) => ({
      metadata: state.metadata,
    }))
  )
  const { session } = useVideosStore(
    useShallow((state) => ({
      session: state.session,
    }))
  )
  const [safetyScore, setSafetyScore] = useState<any[]>([])

  const [props, setProps] = useState<Options>({
    duration: 0,
    elapsed: 0,
    markerPointPosition: PADDING,
    markerPosition: 0,
    markerVisible: false,
    rate: '1x',
    width: 0,
    mouseMove: false,
  })

  const [searchParams] = useSearchParams()
  const offsetURL = parseInt(searchParams.get('offset') || '0')

  useEffect(() => {
    if (ref.current && begin && props.width && context && end) {
      const left = ((props.width * context) / props.duration) * 1000
      const right = ((props.width * (end - begin)) / props.duration) * 1000
      ref.current.style.setProperty('--width', right + 'px')
      ref.current.style.setProperty('--left', left + 'px')
    }
  }, [ref, begin, props, end])

  useEffect(() => {
    if (hoverSync && !props.mouseMove) {
      setProps({
        ...props,
        elapsed: hoverSync,
        markerVisible: true,
        markerPointPosition:
          ((props.width * (hoverSync - begin + context)) / props.duration) *
          1000,
      })
    }
  }, [hoverSync, begin, context, props.mouseMove])

  useEffect(() => {
    if (session) {
      setProps({
        ...props,
        duration: toDurationTimeMs(
          session.offsetURLStartTimestamp || session.startTimestamp,
          session.offsetURLEndTimestamp || session.endTimestamp
        ),
      })
    }
  }, [session])

  useEffect(() => {
    if (ref.current) {
      setProps({
        ...props,
        width: ref.current.offsetWidth,
      })
    }
  }, [ref])

  useEffect(() => {
    if (ref.current && props.duration && !begin && !context) {
      setProps({
        ...props,
        markerPosition: ((props.width * offset) / props.duration) * 1000,
      })
    }
  }, [offset, props.duration, props.width])

  useEffect(() => {
    if (
      ref.current &&
      props.duration &&
      begin !== undefined &&
      context &&
      offset
    ) {
      setProps({
        ...props,
        markerPosition:
          ((props.width * (offset - begin + context)) / props.duration) * 1000,
      })
    }
  }, [offset, props.duration, props.width, begin, context])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setProps({
        ...props,
        markerPosition:
          ((props.width * (offset - begin + context)) / props.duration) * 1000,
      })
    })
  }, [props])

  useEffect(() => {
    if (metadata.safetyScore && session) {
      const results = metadata.safetyScore.map((safety) => {
        return getScorePixelPositions({
          ref,
          safetyScore: safety,
          session,
          duration: props.duration,
        })
      })
      setSafetyScore(results)
    }
  }, [metadata, session])

  const onLineClick = useCallback(
    (event: React.MouseEvent) => {
      if (ref.current) {
        const bounds = ref.current.getBoundingClientRect()
        const position = event.pageX - bounds.x
        setProps({
          ...props,
          markerPosition: position,
        })
        onJumpTo(
          begin - context + ((position / props.width) * props.duration) / 1000
        )
      }
    },
    [ref, props, begin, context]
  )

  const onMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const clientX = event.clientX
      if (
        ref.current &&
        clientX <= ref.current.offsetWidth + PADDING &&
        clientX >= PADDING
      ) {
        const bounds = ref.current.getBoundingClientRect()
        const position = (event.pageX - bounds.x) / props.width
        setProps({
          ...props,
          markerPointPosition: clientX,
          markerVisible: true,
          mouseMove: true,
        })
        update({
          hoverSync:
            (begin > 0 ? begin - context : context) +
            (position * props.duration) / 1000,
        })
      }
    },
    [ref.current, props, begin, context]
  )

  const resetStateWhenLeave = useCallback(() => {
    setProps({
      ...props,
      elapsed: 0,
      markerVisible: false,
      markerPointPosition: 0,
      mouseMove: false,
    })
    update({
      hoverSync: null,
    })
  }, [props])

  const replay = useCallback(() => {
    const position = 0
    setProps({
      ...props,
      markerPosition: position,
    })
    onJumpTo(
      begin - context + ((position / props.width) * props.duration) / 1000
    )
    update({ playing: true })
  }, [props, begin, context])

  const play = useCallback(() => {
    update({ playing: true })
  }, [])

  const shouldReplay = !playing && props.markerPosition >= props.width

  return (
    <div className={style.playbackControls} onMouseLeave={resetStateWhenLeave}>
      <div
        className={classNames(style.line)}
        ref={ref}
        onMouseMove={onMouseMove}
        onClick={onLineClick}
      >
        <div className={style.scoreContainer}>
          <div className={style.scoreTop}>
            {safetyScore?.map(
              (s, i) =>
                s.backgroundColor && (
                  <span
                    key={`${i}_${s}`}
                    className={style.score}
                    style={{
                      left: s.left,
                      backgroundColor: s.backgroundColor,
                      width: s.width,
                    }}
                  />
                )
            )}
          </div>
          <div className={style.scoreBottom}>
            {safetyScore?.map(
              (s, i) =>
                s.backgroundColor && (
                  <span
                    key={`${i}_${s}`}
                    className={style.score}
                    style={{
                      left: s.left,
                      backgroundColor: s.backgroundColor,
                      width: s.width,
                    }}
                  />
                )
            )}
          </div>
        </div>
        <MarkerIcon
          className={style.marker}
          style={{
            left: `${props.markerPosition - PADDING}px`,
          }}
        />
      </div>
      {props.markerVisible && (
        <div
          className={style.tooltipContainer}
          style={{
            left: `${props.markerPointPosition}px`,
          }}
        >
          <Tooltip
            className={style.tooltipVisible}
            text={`date: ${formatTimestampToDate(
              session?.offsetURLStartTimestamp || session?.startTimestamp
            )}\ntime: ${formatTime(
              session?.offsetURLStartTimestamp || session?.startTimestamp,
              props.elapsed ? props.elapsed - offset : offset,
              true
            )}\nUnix Epoc Time: ${
              new Date(
                session?.offsetURLStartTimestamp || session?.startTimestamp
              ).getTime() / 1000
            }\noffset: ${
              props.elapsed ? props.elapsed.toFixed(3) : offset.toFixed(3)
            } sec`}
            position={'top'}
            positionX={
              props.markerPointPosition <=
              props.width - (80 / 100) * props.width
                ? 'left'
                : props.markerPointPosition >=
                  props.width - (20 / 100) * props.width
                ? 'right'
                : undefined
            }
          >
            <MarkerIcon className={style.markerPoint} onClick={onLineClick} />
          </Tooltip>
        </div>
      )}
      <div className={style.playback} onMouseEnter={resetStateWhenLeave}>
        <div className={style.info}>
          <Tooltip
            text={`START TIME\n${formatTime(
              session?.offsetURLStartTimestamp || session?.startTimestamp,
              0
            )}\n\nEND TIME\n${formatTime(
              session?.offsetURLEndTimestamp || session?.endTimestamp,
              0
            )}\n\nDURATION\n${toDurationPrecise(
              session?.offsetURLStartTimestamp || session?.startTimestamp,
              session?.offsetURLEndTimestamp || session?.endTimestamp
            )}`}
            position={'top'}
            positionX={'left'}
          >
            <Icon name={'Error'} size={IconSizes.large} />
          </Tooltip>
          <Typography
            type={TypographyTypes.body}
            size={TypographySizes.small}
            styles={TypographyStyles.mono}
            version={Version.v2}
          >
            {(session?.offsetURLStartTimestamp || session?.startTimestamp) &&
              formatTime(
                session?.offsetURLStartTimestamp || session?.startTimestamp,
                props.elapsed ? props.elapsed - offset : offset - offsetURL
              )}
          </Typography>
        </div>
        <div className={style.controls}>
          <Icon
            name={'SkipPrevious'}
            size={IconSizes.large}
            onClick={() => {
              update({
                jump: offset - 5,
              })
            }}
          />
          {playing ? (
            <Icon
              name={'Pause'}
              size={IconSizes.large}
              onClick={() => {
                update({
                  playing: false,
                })
              }}
            />
          ) : (
            <Icon
              name={shouldReplay ? 'Replay' : 'Play'}
              size={IconSizes.large}
              version={Version.v2}
              onClick={shouldReplay ? replay : play}
            />
          )}
          <Icon
            name={'SkipNext'}
            size={IconSizes.large}
            onClick={() => {
              update({
                jump: offset + 5,
              })
            }}
          />
        </div>
        <div className={style.right}>
          <Select
            variant="outlined"
            value={props.rate}
            className={style.speedSelect}
            IconComponent={(_props) => {
              const opened = _props.className.toString().includes('iconOpen')
              return (
                <Icon
                  className={style.iconSelect}
                  version={Version.v2}
                  name={opened ? 'ArrowDropUp' : 'ArrowDropDown'}
                />
              )
            }}
            onChange={(event) => {
              const rate = event.target.value
              setProps({
                ...props,
                rate,
              })
              update({
                playbackRate: parseFloat(rate),
              })
            }}
          >
            {availableRates.map((rate, index) => (
              <MenuItem key={index} value={rate}>
                <Typography
                  type={TypographyTypes.body}
                  size={TypographySizes.small}
                  version={Version.v2}
                >
                  {rate}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
    </div>
  )
}

export default Playback
