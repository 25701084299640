import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import queryBarStyle from '../SearchQueryBar/style.less'
import endOfDay from 'date-fns/endOfDay'
import { useSearchParams } from 'react-router-dom'
import DateRangePicker from 'rsuite/DateRangePicker'
import { predefinedRanges } from '@yaak/components/helpers/dateRangePicker'
import { MergedListKey } from '../SearchQueryBar/SearchQueryBar'

export interface FilterMenuInputTagProps {
  tag: Record<MergedListKey, string | boolean>
  range: [Date, Date] | null | undefined
  setRange: Dispatch<SetStateAction<[Date, Date] | null | undefined>>
}

const FilterMenuDateTag = ({ range, setRange }: FilterMenuInputTagProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const begin = searchParams.getAll('begin')[0]
    const end = searchParams.getAll('end')[0]
    if (begin && end) {
      setRange([new Date(begin), new Date(end)])
    } else {
      setRange(null)
    }
  }, [searchParams])

  const onDatePickerChange = useCallback(
    (value: any) => {
      if (value?.[0] && value?.[1]) {
        searchParams.set('begin', `${endOfDay(value[0]).toISOString()}`)
        searchParams.set('end', `${endOfDay(value[1]).toISOString()}`)
      } else {
        searchParams.delete('begin')
        searchParams.delete('end')
        setRange(null)
      }
      setSearchParams(searchParams)
    },
    [searchParams]
  )

  return (
    <div className={queryBarStyle.datePickerContainer}>
      <DateRangePicker
        character={'-'}
        size="lg"
        ranges={predefinedRanges}
        placement={'bottomStart'}
        onChange={onDatePickerChange}
        value={range}
      />
    </div>
  )
}

export default memo(FilterMenuDateTag)
